import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import ReactTooltip from "react-tooltip";
import { toastr } from 'react-redux-toastr';
import firebase from '../../servicos/FirebaseConnection';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FlatList from 'flatlist-react';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import { Modal } from 'react-responsive-modal';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, verificaSeExisteConfiguracaoCriada,
    novalistaInformacoesDashBoard, novalistaInformacoesDashBoarPeriodoPersonalizado,
    cadastraTarefas, excluirTarefa, marcarTarefaComoFeito, modificaModalSair, desligaOuvintesChamadosFirebase,
    exportaAtendimentosEconversasLGPD, modificaMostraVersaoExpirando, ativaDesativaRenovarPLano
} from '../../actions/DashBoardAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { listaAvisosTelaDashBoard } from '../../actions/AvisoAction';
import {
    Title, Container, ContainerBox, Box, TextStatus, TextResultado, ContainerGrafico, SubContainerGrafico,
    ContainerTextGrafico, TextGrafico, ContainerAviso, TextAviso, SubContainerListaDeTarefas, TextTarefas,
    SubContainerInputEmail, InputEmail, DivContainerSelectDate, BotaoPesquisarChamadoPorNumero,
    DivTextPeriodo, TextPeriodo, DivPeriodoBotao, BoxLoading, ParagrafoLoading, SubContainerGraficoLoading,
    TextBoxLoading, ContainerGraficoLoading, TextGraficoLoading, ContainerTextGraficoLoading, GraficoLoading,
    IconeAddTarefa, ContainerTarefas, InputTarefa, SubContainerInputTarefa,
    IconeTarefa, CheckManterConectado,
    TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento, ButtonCancelarAtendimento,
    DivInputMotivoCancelamento, ContainerAtendentes, TextCabecalhoModalAcoes, TextNovoAtendimento,
    ContainerIconeFecharModal, ContainerModalPrioridadeAtendimento, IconeFechar,
    DivMensagemSair,
} from './styled';

class Dashboard extends Component {
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,

        aviso: [],
        tarefas: [
            { id: 1, tarefa: 'Aprender git hub' },
            { id: 2, tarefa: 'Instalar windows na maquina' }],
        inputFeitos: [],
        nomeTarefa: '',
        nomeTarefaAlteracao: '',
        diasRestantesBoleto: null
    }

    componentDidMount() {

        this.props.verificaSeExisteConfiguracaoCriada();
        this.props.novalistaInformacoesDashBoard();
        this.props.listaAvisosTelaDashBoard();
        moment.locale('pt-br', {
            months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
        })
        if (this.props.nomeRota !== 'Dashboard') {
            this.props.modificaNomeRotaNoHeader('Dashboard')
        }

    }
    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('avisos', '', '', '');
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataValidoAte && !this.props.mostraVersaoExpirando) {
            let dataAtual = this.retornaDataAtual();
            let diasRestantesBoleto = this.calculaDiasRestantesVersaoGratis(dataAtual, nextProps.dataValidoAte);
            if (diasRestantesBoleto <= 10 && nextProps.statusAssinatura == 'boleto' || diasRestantesBoleto <= 10 && nextProps.statusAssinatura == 'pix') {
                this.props.modificaMostraVersaoExpirando(true)
                this.setState({ diasRestantesBoleto: diasRestantesBoleto })
            } else {
                this.props.modificaMostraVersaoExpirando(false)
                //    this.setState({ mostraVersaoExpirando: false });
                this.props.ativaDesativaRenovarPLano(false);
            }
        }
    }
    /*  shouldComponentUpdate(nextProps, nextState){
         if (nextProps.id !== this.props.id) {
           return true;
         }
         return false;
       } */
    handleInputEmailBlur() {
        this.props.modificaCorBordaListaTarefas(this.props.corBordaDinamica);


    }


    calculaDiasRestantesVersaoGratis(date1, date2) {
        let data1 = moment(date1, 'DD/MM/YYYY');
        let data2 = moment(date2, 'DD/MM/YYYY');
        let diff = Math.abs(data2.diff(data1, 'days'));
        return diff;
    }
    retornaDataAtual() {
        moment.locale('pt-br')
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        return dataAtual;
    }
    modificaNomeTarefa(valor) {
        let state = this.state;
        state.nomeTarefa = valor;
        this.setState({ state })
    }
    modificaNomeTarefaAlteracao(valor) {
        let state = this.state;
        state.nomeTarefaAlteracao = valor;
        this.setState({ state })
    }
    handleInputEmailFocus() {
        this.props.modificaCorBordaListaTarefas(this.props.corBotaoPadrao);
    }
    renderAvisos(item) {

        //   
        return (
            <ContainerAviso key={item.key}>
                <TextAviso>Aviso: {item.aviso}</TextAviso>
            </ContainerAviso>

        )
    }
    renderTarefas(item) {
        // 
        return (
            <SubContainerInputTarefa key={item.key} corBordaInputEmail={this.props.corBotaoPadrao} corBordaPadrao={this.props.coresLetraSecundario}>
                <CheckManterConectado
                    type="checkbox"
                    //value={this.props.checkBoxConectarAutomaticamente}
                    checked={item.feito}
                    onChange={(value) => { this.props.marcarTarefaComoFeito(item.key, value.target.checked) }}
                // onClick={() => { alert('manter conectado') }}
                />
                <InputTarefa
                    //ref={(input) => { this.input = input }}
                    disabled
                    colorDinamico={this.props.coresLetraPadrao}
                    placeholderDinamico={this.props.coresLetraSecundario}
                    corBotaoPadrao={this.props.corBotaoPadrao}
                    type="text"
                    placeholder="Nome Tarefa"
                    feito={item.feito}
                    value={item.tarefa}
                //onChange={(e) => { this.modificaNomeTarefaAlteracao(e.target.value) }}
                />
                <IconeTarefa colorDinamico={this.props.coresLetraPadrao} onClick={() => {
                    this.props.excluirTarefa(item.key)
                }} className="icon-rubbish-bin" />
            </SubContainerInputTarefa>


        )
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    enterPressed(event) {

        let code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            if (this.props.premium == 0) {
                toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            } else {
                if (this.state.nomeTarefa.trim() == '') {

                } else {
                    this.props.cadastraTarefas(this.state.nomeTarefa.trim());
                    this.modificaNomeTarefa('');
                }
            }
        }
    }
    insereDataInicialFinal = ({ dataInicial, dataFinal }) => {
        // 
        // 
        if (dataInicial !== null) {
            let dataInicio = moment(dataInicial, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.startDate = dataInicio;
            this.setState({ state })
        }
        if (dataFinal !== null) {
            let dataFim = moment(dataFinal, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.endDate = dataFim;
            this.setState({ state })
        }

    }
    renderLoadingDashBoard() {
        if (this.props.loadingDashBoard) {
            return (
                <Container backgrounddinamico={this.props.backgroundPrimeiro}>
                    <ContainerBox>

                        <BoxLoading backgroundinamico={'#00BFFF'}>
                            <TextBoxLoading backgroundinamico={'#00BFFF'}></TextBoxLoading>
                            <TextBoxLoading backgroundinamico={'#00BFFF'}></TextBoxLoading>
                        </BoxLoading>
                        <BoxLoading backgroundinamico={'#F9BF3B'}>
                            <TextBoxLoading backgroundinamico={'#F9BF3B'}></TextBoxLoading>
                            <TextBoxLoading backgroundinamico={'#F9BF3B'}></TextBoxLoading>
                        </BoxLoading>
                        <BoxLoading backgroundinamico={'#77DD77'}>
                            <TextBoxLoading backgroundinamico={'#77DD77'}></TextBoxLoading>
                            <TextBoxLoading backgroundinamico={'#77DD77'}></TextBoxLoading>
                        </BoxLoading>
                        <BoxLoading backgroundinamico={'#f70e4c'}>
                            <TextBoxLoading backgroundinamico={'#f70e4c'}></TextBoxLoading>
                            <TextBoxLoading backgroundinamico={'#f70e4c'}></TextBoxLoading>
                        </BoxLoading>
                        <BoxLoading backgroundinamico={'#D2691E'}>
                            <TextBoxLoading backgroundinamico={'#D2691E'}></TextBoxLoading>
                            <TextBoxLoading backgroundinamico={'#D2691E'}></TextBoxLoading>
                        </BoxLoading>
                        <BoxLoading backgroundinamico={'#20B2AA'}>
                            <TextBoxLoading backgroundinamico={'#20B2AA'}></TextBoxLoading>
                            <TextBoxLoading backgroundinamico={'#20B2AA'}></TextBoxLoading>
                        </BoxLoading>
                    </ContainerBox>
                    <ContainerGrafico>

                        <SubContainerGraficoLoading backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGraficoLoading>
                        <SubContainerGraficoLoading backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGraficoLoading>
                    </ContainerGrafico>
                    <ContainerGrafico>

                        <SubContainerGraficoLoading backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGraficoLoading>
                        <SubContainerGraficoLoading backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGraficoLoading>
                    </ContainerGrafico>

                </Container>
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    {this.props.mostraVersaoExpirando == true && this.props.isAdmin === true && <div onClick={() => this.props.ativaDesativaRenovarPLano(true)} style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', paddingVertical: 7, backgroundColor: '#FFFFDD' }}>
                        <span style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Sua Versão Premium EXPIRARÁ em menos de {this.state.diasRestantesBoleto} dias, <Link style={{ color: '#39A3F4' }} to="/versaopremium">clique aqui</Link> para escolher um novo plano e gerar um novo PIX/boleto para renovar.</span>
                    </div>}
                    {this.props.avisosTelaDashBoard.length !== 0 &&
                        <FlatList
                            //    style={{ flex: 1 }}
                            //  contentContainerStyle={{ flexGrow: 1 }}
                            list={this.props.avisosTelaDashBoard}
                            renderWhenEmpty={() => <></>}
                            //   extraData={this.props}
                            // keyExtractor={(item, index) => item.key + item.alterado}
                            renderItem={(item) => this.renderAvisos(item)}
                        />}
                    {this.props.filtroPorDataDashboard !== '' && <DivContainerSelectDate>
                        <div style={{}}>
                            <DivTextPeriodo>
                                <i className="icon-calendarweb" style={{ color: this.props.coresLetraPadrao, fontSize: 15, marginRight: 3 }} />
                                <TextPeriodo corLetraDinamica={this.props.coresLetraPadrao}>Período:</TextPeriodo>
                            </DivTextPeriodo>
                            <DivPeriodoBotao >
                                <DateRangePicker
                                    // customInputIcon={<i className="icon-calendar" style={{ color: '#000', marginRight: 5 }} />}
                                    //   customArrowIcon={<Icon icon="next" size="xxs" />}
                                    // customCloseIcon={<i className="icon-close" style={{ color: '#000', marginRight: 5 }} />}
                                    displayFormat={() => "DD/MM/YYYY"}
                                    small
                                    isOutsideRange={() => false}
                                    minimumNights={0}
                                    firstDayOfWeek={1}
                                    renderDayContents={this.handleWeekDays}
                                    isDayHighlighted={day => isSameDay(moment(new Date()), moment(day))}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText="Data Inicial"
                                    endDatePlaceholderText="Data Final"
                                    startDate={this.props.dataInicialConsulta} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.props.dataFinalConsulta} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    //onDatesChange={this.insereDataInicialFinal} // PropTypes.func.isRequired,
                                    onDatesChange={({ startDate, endDate }) => this.props.insereDataFiltroDashboard(startDate, endDate)}
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                />
                                {this.props.dataInicialConsulta !== null && this.props.dataFinalConsulta !== null && <BotaoPesquisarChamadoPorNumero onClick={() => { this.props.novalistaInformacoesDashBoarPeriodoPersonalizado(this.props.dataInicialConsulta, this.props.dataFinalConsulta) }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                                    <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 17 }} />
                                </BotaoPesquisarChamadoPorNumero>}
                            </DivPeriodoBotao>
                        </div>
                    </DivContainerSelectDate>}
                    <ContainerBox>
                        <Box backgroundDinamico={'#00BFFF'}>
                            <TextStatus colorDinamico={this.props.coresLetraPadrao}>Aberto</TextStatus>
                            <TextResultado colorDinamico={this.props.coresLetraPadrao}>{this.props.atendimentoAberto}</TextResultado>
                        </Box>
                        <Box backgroundDinamico={'#F9BF3B'}>
                            <TextStatus colorDinamico={this.props.coresLetraPadrao}>Atendendo</TextStatus>
                            <TextResultado colorDinamico={this.props.coresLetraPadrao}>{this.props.atendimentoEmAtendimento}</TextResultado>
                        </Box>
                        <Box backgroundDinamico={'#77DD77'}>
                            <TextStatus colorDinamico={this.props.coresLetraPadrao}>Fechado</TextStatus>
                            <TextResultado colorDinamico={this.props.coresLetraPadrao}>{this.props.atendimentoFechado}</TextResultado>
                        </Box>
                        <Box backgroundDinamico={'#f70e4c'}>
                            <TextStatus colorDinamico={this.props.coresLetraPadrao}>Cancelado</TextStatus>
                            <TextResultado colorDinamico={this.props.coresLetraPadrao}>{this.props.atendimentoCancelado}</TextResultado>
                        </Box>
                        <Box backgroundDinamico={'#D2691E'}>
                            <TextStatus colorDinamico={this.props.coresLetraPadrao}>Hoje</TextStatus>
                            <TextResultado colorDinamico={this.props.coresLetraPadrao}>{this.props.atendimentosHoje}</TextResultado>
                        </Box>
                        <Box backgroundDinamico={'#20B2AA'}>
                            <TextStatus colorDinamico={this.props.coresLetraPadrao}>no Periodo</TextStatus>
                            <TextResultado colorDinamico={this.props.coresLetraPadrao}>{this.props.atendimentosEstaSemana}</TextResultado>
                        </Box>
                    </ContainerBox>
                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Atendimentos ABERTO por Prioridade</TextGrafico>
                            </ContainerTextGrafico>

                            <ResponsivePie
                                data={this.props.atendimentosPorPrioridade}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'ruby'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'c'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'go'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'python'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'scala'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'lisp'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'elixir'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'javascript'
                                        },
                                        id: 'lines'
                                    }
                                ]}

                            />

                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Tempo de Solução dos Atendimentos</TextGrafico>
                            </ContainerTextGrafico>
                            <ResponsivePie
                                data={this.props.atendimentosPorTempoResolucao}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'ruby'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'c'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'go'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'python'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'scala'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'lisp'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'elixir'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'javascript'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                    </ContainerGrafico>
                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Atendimentos Interno/Externo</TextGrafico>
                            </ContainerTextGrafico>

                            <ResponsivePie
                                data={this.props.atendimentosPorLocal}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'ruby'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'c'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'go'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'python'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'scala'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'lisp'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'elixir'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'javascript'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                            /* legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 56,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: this.props.coresLetraPadrao,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: this.props.coresLetraSecundario
                                            }
                                        }
                                    ]
                                }
                            ]} */
                            />

                        </SubContainerGrafico >
                        <SubContainerListaDeTarefas backgroundDinamico={this.props.backgroundSegundo}>

                            <ContainerTextGrafico>
                                <TextTarefas colorDinamico={this.props.coresLetraPadrao}>Lista de Tarefas {this.props.tarefas.length > 0 && <TextTarefas>({this.props.tarefas.length})</TextTarefas>}</TextTarefas>
                            </ContainerTextGrafico>

                            <SubContainerInputEmail corBordaInputEmail={this.props.corBotaoPadrao} corBordaPadrao={this.props.coresLetraPadrao}>
                                <IconeAddTarefa onClick={() => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    } else {
                                        if (this.state.nomeTarefa.trim() == '') {

                                        } else {
                                            this.props.cadastraTarefas(this.state.nomeTarefa.trim());
                                            this.modificaNomeTarefa('');
                                        }
                                    }
                                }} className="icon-add2" />
                                <InputEmail
                                    onKeyPress={this.enterPressed.bind(this)}
                                    type="text"
                                    placeholder="Adicione suas tarefas"
                                    value={this.state.nomeTarefa}
                                    onChange={(e) => { this.modificaNomeTarefa(e.target.value) }}
                                />

                            </SubContainerInputEmail>
                            <ContainerTarefas>
                                {this.props.tarefas.length !== 0 &&
                                    <FlatList
                                        //    style={{ flex: 1 }}
                                        //  contentContainerStyle={{ flexGrow: 1 }}
                                        list={this.props.tarefas}
                                        renderWhenEmpty={() => <></>}
                                        //   extraData={this.props}
                                        // keyExtractor={(item, index) => item.key + item.alterado}
                                        renderItem={(item) => this.renderTarefas(item)}
                                    />}
                            </ContainerTarefas>
                        </SubContainerListaDeTarefas>
                    </ContainerGrafico>
                </Container>
            )
        }
    }
    render() {

        return (
            <>
                {this.renderLoadingDashBoard()}
                {/* <Modal focusTrapped={false} showCloseIcon={false} open={this.props.sairDoDropDesk} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Sair</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaModalSair(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <DivMensagemSair>
                                <h2 style={{ color: this.props.coresLetraPadrao }}>Deseja realmente Sair?</h2>
                            </DivMensagemSair>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.props.modificaModalSair(false) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    setTimeout(() => {
                                        this.props.modificaModalSair(false);
                                        this.setState({ isLogged: false });
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('login');
                                        localStorage.removeItem('chaveUnica');
                                        localStorage.removeItem('configuracoesInseridas3');
                                        firebase.auth().signOut();
                                    }, 100);
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim </TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal> */}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {

            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            nomeRota: state.HeaderReducer.nomeRota,
            corBordaPadrao: state.CoresPadraoSistema.corBordaPadrao,
            corBordaDinamica: state.CoresPadraoSistema.corBordaDinamica,
            filtroPorDataDashboard: state.DashBoardReducer.filtroPorDataDashboard,
            dataInicialConsulta: state.DashBoardReducer.dataInicialConsulta,
            dataFinalConsulta: state.DashBoardReducer.dataFinalConsulta,

            atendimentosHoje: state.DashBoardReducer.atendimentosHoje,
            atendimentosEstaSemana: state.DashBoardReducer.atendimentosEstaSemana,
            clientesNoTotal: state.DashBoardReducer.clientesNoTotal,
            atendimentosNoTotal: state.DashBoardReducer.atendimentosNoTotal,
            atendimentosPorStatus: state.DashBoardReducer.atendimentosPorStatus,
            atendimentosPorPrioridade: state.DashBoardReducer.atendimentosPorPrioridade,
            loadingDashBoard: state.DashBoardReducer.loadingDashBoard,
            loadingRefreshControl: state.DashBoardReducer.loadingRefreshControl,
            totalAtendimentoPorPrioridade: state.DashBoardReducer.totalAtendimentoPorPrioridade,
            clientesMaisAbriramChamados: state.DashBoardReducer.clientesMaisAbriramChamados,
            setoresMaisAbriramChamados: state.DashBoardReducer.setoresMaisAbriramChamados,
            atendimentosPorTempoResolucao: state.DashBoardReducer.atendimentosPorTempoResolucao,
            atendimentosPorLocal: state.DashBoardReducer.atendimentosPorLocal,
            totalAtendimentoPorLocal: state.DashBoardReducer.totalAtendimentoPorLocal,
            totalPeriodoResolucaoAtendimento: state.DashBoardReducer.totalPeriodoResolucaoAtendimento,
            semInternetDashBoard: state.DashBoardReducer.semInternetDashBoard,
            premium: state.PremiumReducer.premium,
            dataValidoAte: state.PremiumReducer.dataValidoAte,
            avisosTelaDashBoard: state.AvisosReducer.avisosTelaDashBoard,
            periodoPersonalizadoRelatorio: state.DashBoardReducer.periodoPersonalizadoRelatorio,
            modalVisibleAdiquiraAssinatura: state.PremiumReducer.modalVisibleAdiquiraAssinatura,
            modalVisibleAssinaturaSuspensa: state.PremiumReducer.modalVisibleAssinaturaSuspensa,
            plano: state.PremiumReducer.plano,
            modalVisibleAssinaturaPausada: state.PremiumReducer.modalVisibleAssinaturaPausada,
            qtdAtendentesLiberados: state.PremiumReducer.qtdAtendentesLiberados,
            modalVisibleVersaoPremiumEsgotou: state.PremiumReducer.modalVisibleVersaoPremiumEsgotou,
            loadingVerificaCarregouConfigAtendimento: state.ChamadoReducer.loadingVerificaCarregouConfigAtendimento,
            licensawebouapp: state.PremiumReducer.licensawebouapp,

            atendimentoAberto: state.DashBoardReducer.atendimentoAberto,
            atendimentoEmAtendimento: state.DashBoardReducer.atendimentoEmAtendimento,
            atendimentoFechado: state.DashBoardReducer.atendimentoFechado,
            atendimentoCancelado: state.DashBoardReducer.atendimentoCancelado,
            tarefas: state.DashBoardReducer.tarefas,
            sairDoDropDesk: state.DashBoardReducer.sairDoDropDesk,
            mostraVersaoExpirando: state.PremiumReducer.mostraVersaoExpirando,
            dataValidoAte: state.PremiumReducer.dataValidoAte,
            statusAssinatura: state.PremiumReducer.statusAssinatura,
            isAdmin: state.PerfilReducer.isAdmin,
            //  abrirMenu: state.HeaderReducer.abrirMenu,
        }
    );
};
export default connect(mapStateToProps, {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaNomeRotaNoHeader,
    verificaSeExisteConfiguracaoCriada, novalistaInformacoesDashBoard,
    listaAvisosTelaDashBoard,
    novalistaInformacoesDashBoarPeriodoPersonalizado,
    cadastraTarefas, excluirTarefa, marcarTarefaComoFeito,
    modificaModalSair, desligaOuvintesChamadosFirebase, exportaAtendimentosEconversasLGPD,
    modificaMostraVersaoExpirando, ativaDesativaRenovarPLano
})(Dashboard);
