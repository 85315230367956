import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Dropzone from 'react-dropzone';
import { withRouter } from "react-router-dom";
import firebase from '../../servicos/FirebaseConnection';
import SelectSearch from 'react-select-search';
import ClipLoader from "react-spinners/ClipLoader";
import styled from 'styled-components';
import { SketchPicker, ChromePicker } from 'react-color';
import { toastr } from 'react-redux-toastr';
import { shade, opacify, lighten, darken } from 'polished';
import TimeRangeSlider from 'react-time-range-slider';
import HashLoader from "react-spinners/HashLoader";
import moment from 'moment';
import api from '../../http_client/http.client';

import 'react-responsive-modal/styles.css';
import './styles.css';
import {
    Title, Container, ContainerCfg, DivCabecalhoCfg, TextCabecalho, DivConfiguracoes, ContainerPermitir,
    TextPermitir, DivInputComentarChamado, InputDescricaoChamado, TextMensagem, InputDescricaoEmpresa,
    IconeInterrogacao, DivCSelectColor, DivColorPiciker, DivCor, DivColor, DivLetraeCor, TextDescCor,
    ContainerCores, ContainerSelectSearch, DivInputSearch, InputSearch, IconeSetaPraBaixo,
    TextBotaoSalvarComentar, ButtonSalvarComentario, DivBotaoSalvarComentario, ContainerPermitirProblemaPReDefinido,
    ButtonSalvarComentarioSemComent, ContainerModalPrioridadeAtendimento, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerAtendentes, ContainerInputEBotaoComent, ContainerBotaoAlterar,
    ButtonEntrar, InputDescricaoChamado2, ContainerModalRespostaPadrao,
    ContainerInputRespostaPadrao, DivBotaoSalvarResposta,
    ButtonEscolherLogoTipo, TextOpcaoLoading, TextInputLoading, TextOpcaoCorLoading, TextNomeEmpresaLoading,
    ButtonResetCores, BotaoAdd, InputDescricaoTituloResposta, ContainerRespostas,
    ContainerImagemVincularEmpresa, ContainerExplicacaoVincular, TextVincular, ButtonVincular,
    ContainerModal, ButtonConectar, ContainerNumeroWpp, DivCabWbb, ContainerModalConectarWpp,
    ButtonCancelarAtendimento, TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento,
    ButtonRemoveToken, DivConfiguracoesWhatsApp, ContainerSelecionaSetorAposLimite, TextHeader, IconeExcluirRespPadrao,
    DivTruncarTexto, IconeExcluirRespPadrao2, ContainerVersaoMd, ContainerModalAtivarMD
} from './styled';
import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";
import ReactTooltip from "react-tooltip";

import {
    zeraMensagemSemInternetConfigAvaliacao, modificaNomeEmpresa,
    alteraMensagemAvaliacao, alteraValorSwitchConfigAvaliacao, zeraMensagemSemInternetConfigEmpresa,

    alteraValorSwitchConfigAtendimento, zeraMensagemSemInternetConfigAtendimento,
    alteraAgrupaAtendenteSetor, alteraAtendenteVeApenasProprioChamado, alteraValorSwitchConfigChamadoEstatico,
    modificaDescricaoProblemaEstatico,
    modificaProblemaSelecionadoSectioned,

    modificaCorFundoPrincipal, modificaCorFundoSecundario, modificaCorFundoItensSelecionaveis, modificaCorFundoBotaoPrincipal,
    modificaCorFundoBotaoSecundario, modificaCorLetraPrincipal, modificaCorLetraSecundaria,
    modificaCorDescricaoDosCampos, modificaCorLetraBotao, modificaCorFundoMenu, modificaCorLetraMenu,
    listaConfiguracoes, cadastraProblemaEstatico, alteraConfiguracaoAtendimento,
    alteraConfiguracaoAvaliacao, alteraNomeEmpresa, resetarCores, modificaImagemCompletaLogoTipoConfiguracao, modificaLogoTipoTelaConfiguracao,
    alteraValorSwitchUtilizarChat, alteraValorSwitcUtilizar2HorariosChat,
    alteraValorCheckSegundaFeira, modificaHorarioOperacaoSegundaFeira2, modificaHorarioOperacaoSegundaFeira,
    modificaHorarioOperacaoTercaFeira2, modificaHorarioOperacaoTercaFeira, modificaHorarioOperacaoQuartaFeira2,
    modificaHorarioOperacaoQuartaFeira, modificaHorarioOperacaoQuintaFeira2, modificaHorarioOperacaoQuintaFeira,
    modificaHorarioOperacaoSextaFeira2, modificaHorarioOperacaoSextaFeira, modificaHorarioOperacaoSabadoFeira, modificaHorarioOperacaoSabadoFeira2,
    modificaHorarioOperacaoDomingoFeira2, modificaHorarioOperacaoDomingoFeira,
    alteraValorCheckTercaFeira, alteraValorCheckQuartaFeira, alteraValorCheckQuintaFeira,
    alteraValorCheckSextaFeira, alteraValorCheckSabadoFeira, alteraValorCheckDomingoFeira, alteraConfiguracaoChatOnline,
    modificaFusoHorarioSelecionado, modificaCorPrincipalChat, modificaMensagemRespostaPadrao, modificaDescricaoRespostaPadrao,
    cadastraRespostaPadrao, modificaRespostaSelecionada, modificaClicouCFGchat, modificaSwitchAdmVisualizaApenasChamadoSetor,

    modificaMensagemBoasVindasWpp, modificaMensagemEscolhaDepartamento, modificaMensagemFilaAtendimento,
    modificaMensagemFinalizacaoChamado, modificaMensagemForaHorarioOperacao, alteraConfiguracaoWhatsApp,
    modificaLimiteDeEsperaFilaChat, alteraValorSwitchEnviaAtendimentoParaSetorAposLimite,
    modificaKeySetorSelecionadoParaEnviarAtendimentoAposLimiteExport, modificaObrigatorioResolucaoChamado,

    modificaMostrarNomeAtendenteMensagem, modificaDesativarAssistenteVirtualWpp, modificaSolicitarNomeClientePrimeirocontato,
    excluirRespostaPadrao, modificaDescricaoRespostaPadraoAlterar, modificaMensagemRespostaPadraoAlterar, alterarRespostaPadrao,
    modificaMotivoCancelamentoConfig, modificaAtendenteVeApenasProprioHistorico, alteraValorSwitchUtilizaVersaoBETA,
    modificaEnviarMensagemTransSetorWppConfig
} from '../../actions/ConfiguracaoAction';

import {
    alteraSwitchNotifAtendABertoPorMim, alteraSwitchNotifAtendEstouAtendendo, alteraSwitchNotifRespAtendAbertoPorMim,
    alteraSwitchNotifRespAtendQueEstouAtendendo,
    verificaConexaoInternetNotificacao, zeraverificaSeAlterouComSucessoNotificacao,
    alterarConfiguracaoNotificao, alteraSwitchNotifComentarioInterno
} from '../../actions/NotificacaoAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';

import logotipo from '../../imagens/logodropdesk2.png';
import backGroundStopVersaoPremium from '../../imagens/backGroundStopVersaoPremium.jpg'
import wppimage from '../../imagens/whatsapp.png'
import whatsappbackground from '../../imagens/whatsapp-logo.png';
import qrcodeimage from '../../imagens/qr-code.png';
import approved from '../../imagens/approved.png';


const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
class Configuracao extends React.Component {
    state = {
        color: '#000',
        modalResetarCores: false,
        modalVisibleDescricaoProblema: false,
        modalVisibleRespostaPadrao: false,
        value: 0,
        displayColorPicker: false,
        displayColorPicker1: false,
        displayColorPicker2: false,
        displayColorPicker3: false,
        displayColorPicker4: false,
        displayColorPicker5: false,
        displayColorPicker6: false,
        displayColorPicker7: false,
        displayColorPicker8: false,
        displayColorPicker9: false,
        displayColorPicker10: false,
        displayColorPicker11: false,
        displayColorPicker12: false,
        search: false,
        mostrarMaisDetalhesChamado: false,
        timezone: "",
        modalAtencaoAdmChamadoSetor: false,

        criandoContainer: true,
        //resetar para false abaixo
        modalConnectWhatsapp: false,
        infoConectadoOk: false,
        modalDesconectarWhatsapp: false,
        desconectandoContainer: false,
        errorConnectarContainer: false,
        modalExcluirToken: false,

        keyRespPadraoAlterar: '',
        modalVisibleRespostaPadraoAlterar: false,

        modalRemoverConexaoVersaoBeta: false,
        removendoConexaoParaBeta: false
    }
    componentWillMount() {
        ReactTooltip.rebuild();
    }
    componentDidMount() {

        /*  let variavelTeste = 'Bruno vê se da pra entender?!@#$%ˆ&*()-+.,``';
         let vari = variavelTeste.replace(/[&\/\\#,+()$~%.'"`ˆ:*?@!<>{}]/g, '');
          */
        /*  let textExemp2 = '1';
         let textExemp = parseInt(textExemp2);
         
         
         let isNan2 = isNaN(textExemp);
          */

        /*       firebase.database().ref(`servidoresDisponiveis`).push({
            urlApi: 'https://apiwpp34socket.dropdesk.app.br',
            numeroContainerDisponivel: 120,
            ipServer: '51.79.18.191',
        })  
        firebase.database().ref(`servidoresDisponiveis`).push({
          urlApi: 'https://apiwpp35socket.dropdesk.app.br',
          numeroContainerDisponivel: 120,
          ipServer: '66.70.176.201',
      })   
      firebase.database().ref(`servidoresDisponiveis`).push({
          urlApi: 'https://apiwpp36socket.dropdesk.app.br',
          numeroContainerDisponivel: 120,
          ipServer: '54.39.133.111',
      })     */

        /* AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
           const chaveUnica = chaveUnica2.replace(/"/g, "");
           firebase.database().ref(`${chaveUnica}/configuracao/whatsapp/`).push({
               qrCode: '',
               sessionclient: "11",
               urlApi: "https://apiwpp11.dropdesk.app.br",
               statusConnect: 'connected', ipServer: "62.171.158.125", serverandport: `62.171.158.12511`
           });
       })  */
        if (this.props.permissoesAcessarTelasAtendente.permissaoAdministrador == true) {
            this.props.listaConfiguracoes()
            //ReactTooltip.rebuild();
            /*  setTimeout(() => {
                 ReactTooltip.rebuild();
             }, 500); */
            if (this.props.nomeRota !== 'Configurações') {
                this.props.modificaNomeRotaNoHeader('Configurações')
            }

        } else {
            toastr.error('Acesso Negado', 'Somente ADMINISTRADOR tem permissão para acessar esta tela');
            this.props.history.push('/dashboard')
        }
        if (this.props.clicouCFGchat == true) {
            this.myRef.scrollIntoView();
            this.props.modificaClicouCFGchat(false);
            this.props.alteraValorSwitchUtilizarChat(true)
            toastr.warning('Atenção', 'Selecione seu Fuso Horário e configure o Horário de Operação da sua Empresa')
        }
    }
    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('configuracoesall', '', '', '');
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.configWhatsapp.length > 0 && nextProps.configWhatsapp.length > 0 &&
            this.props.configWhatsapp !== nextProps.configWhatsapp) {
            if (nextProps.configWhatsapp[0].statusConnect === 'connected' || nextProps.configWhatsapp[0].statusConnect === 'chatsAvailable' ||
                nextProps.configWhatsapp[0].statusConnect === 'isConnected' || nextProps.configWhatsapp[0].statusConnect === 'isSync' || nextProps.configWhatsapp[0].statusConnect === 'isLogged')
                this.setState({ infoConectadoOk: true });
        }

        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 1000);
    }
    escolheImagemLogoTipo(res) {
        if (res.length > 0) {
            //
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            // 
            this.props.modificaImagemCompletaLogoTipoConfiguracao(res[0])
            this.props.modificaLogoTipoTelaConfiguracao(res[0].uri);
        } else {
            toastr.error('Error ao Selecionar', 'Selecione uma logotipo com no máximo 5mb de tamanho');

        }
    }
    renderImagemLogoTipo() {
        if (this.props.logotipo == null) {
            return (
                <div style={{ height: 256, width: 256, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />

            )
        } else {
            return (
                <img src={this.props.logotipo === 'semURI' ? logotipo : this.props.logotipo} alt="Logotipo" style={{ height: 256, width: 256 }} />
            )
        }

    }
    changeStartHandler(time) {
        //
    }

    timeChangeHandler(time) {
        this.setState({
            value: time
        });
    }

    changeCompleteHandler(time) {
        //
    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    handleClick1 = () => {
        this.setState({ displayColorPicker1: !this.state.displayColorPicker1 })
    };
    handleClick2 = () => {
        this.setState({ displayColorPicker2: !this.state.displayColorPicker2 })
    };
    handleClick3 = () => {
        this.setState({ displayColorPicker3: !this.state.displayColorPicker3 })
    };
    handleClick4 = () => {
        this.setState({ displayColorPicker4: !this.state.displayColorPicker4 })
    };
    handleClick5 = () => {
        this.setState({ displayColorPicker5: !this.state.displayColorPicker5 })
    };
    handleClick6 = () => {
        this.setState({ displayColorPicker6: !this.state.displayColorPicker6 })
    };
    handleClick7 = () => {
        this.setState({ displayColorPicker7: !this.state.displayColorPicker7 })
    };
    handleClick8 = () => {
        this.setState({ displayColorPicker8: !this.state.displayColorPicker8 })
    };
    handleClick9 = () => {
        this.setState({ displayColorPicker9: !this.state.displayColorPicker9 })
    };
    handleClick10 = () => {
        this.setState({ displayColorPicker10: !this.state.displayColorPicker10 })
    };
    handleClick11 = () => {
        this.setState({ displayColorPicker11: !this.state.displayColorPicker11 })
    };
    handleClick12 = () => {
        this.setState({ displayColorPicker12: !this.state.displayColorPicker12 })
    };

    handleTimezoneChange = (timezone) => {
        // 
        this.setState({ timezone });
    }
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    _toggleModalVisibleDescricaoProblema() {
        this.setState({ modalVisibleDescricaoProblema: !this.state.modalVisibleDescricaoProblema })
    }
    _toggleModalVisibleRespostaPadrao() {
        this.setState({ modalVisibleRespostaPadrao: !this.state.modalVisibleRespostaPadrao })
    }
    toggleModalConectarWhatsapp() {
        this.setState({ modalConnectWhatsapp: !this.state.modalConnectWhatsapp })
    }

    _toggleModalResetarcores() {
        this.setState({ modalResetarCores: !this.state.modalResetarCores })
    }
    toggleModalDesconectarWhatsapp() {
        this.setState({ modalDesconectarWhatsapp: !this.state.modalDesconectarWhatsapp })
    }
    toggleModalExcluiTokenWhatsapp() {
        this.setState({ modalExcluirToken: !this.state.modalExcluirToken })
    }

    handleClose1 = () => {
        this.setState({ displayColorPicker1: false })
    };
    handleClose2 = () => {
        this.setState({ displayColorPicker2: false })
    };
    handleClose3 = () => {
        this.setState({ displayColorPicker3: false })
    };
    handleClose4 = () => {
        this.setState({ displayColorPicker4: false })
    };
    handleClose5 = () => {
        this.setState({ displayColorPicker5: false })
    };
    handleClose6 = () => {
        this.setState({ displayColorPicker6: false })
    };
    handleClose7 = () => {
        this.setState({ displayColorPicker7: false })
    };
    handleClose8 = () => {
        this.setState({ displayColorPicker8: false })
    };
    handleClose9 = () => {
        this.setState({ displayColorPicker9: false })
    };
    handleClose10 = () => {
        this.setState({ displayColorPicker10: false })
    };
    handleClose11 = () => {
        this.setState({ displayColorPicker11: false })
    };
    handleClose12 = () => {
        this.setState({ displayColorPicker12: false })
    };

    handleChange = (color) => {
        // 
        this.setState({ color: color.hex })
    };
    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    setDataHoraLocal() {
        // Cria um objeto Date com a data e hora atual
        const now = new Date();

        // Armazena a data e hora local no armazenamento local
        localStorage.setItem('dataHoraLocal', now.toISOString());
    }

    eligibleCreateContainer() {
        // Cria um objeto Date com a data e hora atual
        const now = new Date();

        // Obtém a data e hora local armazenada no armazenamento local
        const dataHoraLocal = localStorage.getItem('dataHoraLocal');
        if (!dataHoraLocal) {
            return true;
        }
        // Cria um objeto Date a partir da data e hora local armazenada
        const dataHoraLocalObj = new Date(dataHoraLocal);
       

        // Calcula a diferença entre a data e hora atual e a data e hora local em milissegundos
        const diferenca = now.getTime() - dataHoraLocalObj.getTime();

        // Verifica se a diferença é menor que 1 minuto (60 segundos)
        if (diferenca < 120000) {
            return false;
        } else {
            return true;
        }
    }
    async criaContainerVenom() {

        AsyncStorage.getItem("chaveUnica").then(async chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (this.eligibleCreateContainer()) {
                this.setDataHoraLocal();
                this.setState({ criandoContainer: true, errorConnectarContainer: false })

                let data = {
                    //sessionCliente: rndInt, //REABILITAR DEPLOY
                    chaveUnica: chaveUnica,
                    utilizaVersaoBETA: this.props.utilizaVersaoBETA
                }
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: data,
                    url: 'createAndConnectContainerHibrid',
                };
                api(options).then((response) => {


                    this.setState({ criandoContainer: false })
                    // this.downloadUsuariosCadastradosExcel(['email'], response.data)
                }).catch((err) => {


                    this.setState({ criandoContainer: false, errorConnectarContainer: true })
                    toastr.error('Erro ao Connectar, Gere o QR Code e Tente Novamente')
                })
            } else {
                alert('Limite de conexão excedido, tente novamente daqui 2 minutos.');
            }
        })
    }
    _toggleModalVisibleRespostaPadraoAlterar() {
        this.setState({ modalVisibleRespostaPadraoAlterar: !this.state.modalVisibleRespostaPadraoAlterar })
    }
    alterarRespostaPadrao(item, key) {

        this._toggleModalVisibleRespostaPadraoAlterar();
        this.props.modificaDescricaoRespostaPadraoAlterar(item.groupName)
        this.props.modificaMensagemRespostaPadraoAlterar(item.name)
        this.setState({ keyRespPadraoAlterar: key })
    }
    somenteConectarContainer() {

        this.setState({ criandoContainer: true, errorConnectarContainer: false })

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");


            /* let sessionClientRandom = Math.floor(
                Date.now() * Math.random()
            )
            sessionClientRandom = sessionClientRandom.toString(); */
            let data = {
                sessionCliente: this.props.configWhatsapp[0].sessionclient, // HERA SESSION 17 ATUAL
                urlApi: this.props.configWhatsapp[0].urlApi,
                // sessionCliente: '17', // HERA SESSION 17 ATUAL
                chaveUnica: chaveUnica,
                utilizaVersaoBETA: this.props.utilizaVersaoBETA
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: data,
                url: 'somenteConectaVenomHibrid',
            };
            api(options).then((response) => {


                this.setState({ criandoContainer: false })
                // this.downloadUsuariosCadastradosExcel(['email'], response.data)
            }).catch((err) => {


                this.setState({ criandoContainer: false, errorConnectarContainer: true })
                toastr.error('Erro ao Connectar, Gere o QR Code e Tente Novamente')
            })
        })
    }
    desconnectarContainerVenom(alterandoMD, utilizaVersaoBETA) {


        this.setState({ desconectandoContainer: true })

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            if (this.props.configWhatsapp[0].urlApi && this.props.configWhatsapp[0].sessionclient) {

                // firebase.database().ref(`${chaveUnica}/configuracao/whatsapp/${this.props.configWhatsapp[0].key}`).update({ statusConnect: '', qrCode: '' });
                /* let sessionClientRandom = Math.floor(
                    Date.now() * Math.random()
                )
                sessionClientRandom = sessionClientRandom.toString(); */
                let data = {
                    sessionCliente: this.props.configWhatsapp[0].sessionclient, // HERA SESSION 17 ATUAL
                    urlApi: this.props.configWhatsapp[0].urlApi,
                    // sessionCliente: '17', // HERA SESSION 17 ATUAL
                    chaveUnica: chaveUnica,
                }
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: data,
                    url: 'desconectarVenom',
                };
                api(options).then((response) => {


                    this.setState({ desconectandoContainer: false, modalRemoverConexaoVersaoBeta: false, removendoConexaoParaBeta: false, modalDesconectarWhatsapp: false, infoConectadoOk: false, modalExcluirToken: false });
                    if (alterandoMD === true) {
                        //this.props.alteraValorSwitchUtilizaVersaoBETA(!utilizaVersaoBETA)
                    }
                    toastr.success('WhatsApp desconectado com Sucesso')
                    // this.downloadUsuariosCadastradosExcel(['email'], response.data)
                }).catch((err) => {


                    this.setState({ desconectandoContainer: false, modalRemoverConexaoVersaoBeta: false, removendoConexaoParaBeta: false, modalDesconectarWhatsapp: false, infoConectadoOk: false, modalExcluirToken: false })
                    toastr.error('Error ao desconectar o WhatsApp, Tente Novamente')
                })
            } else {
                firebase.database().ref(`${chaveUnica}/configuracao/whatsapp`).remove().then(() => {
                    this.setState({ desconectandoContainer: false, modalRemoverConexaoVersaoBeta: false, removendoConexaoParaBeta: false, modalDesconectarWhatsapp: false, infoConectadoOk: false, modalExcluirToken: false });
                    toastr.success('WhatsApp desconectado com Sucesso');
                });

            }
        })
    }
    calculaDias2(date1, date2) {
        //formato do brasil 'pt-br'
        // moment.locale('pt-br');
        //setando data1
        let data1 = moment(date1, 'DD/MM/YYYY');
        //setando data2
        let data2 = moment(date2, 'DD/MM/YYYY');
        //tirando a diferenca da data2 - data1 em dias
        // let diff = data2.diff(data1, 'days');
        let diff = moment.duration(data2.diff(data1)).asDays();

        return diff;
    }
    retornaDataAtual() {
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        return dataAtual;
    }
    renderQtdAtendLiberados() {
        if (this.props.qtdAtendentesLiberados == 999) {
            return 'ilimitado';
        } else {
            return this.props.qtdAtendentesLiberados;
        }
    }
    calculaDiasRestantesVersaoGratis(date1, date2) {
        //formato do brasil 'pt-br'
        // moment.locale('pt-br');
        //setando data1
        let data1 = moment(date1, 'DD/MM/YYYY');
        //setando data2
        let data2 = moment(date2, 'DD/MM/YYYY');

        //tirando a diferenca da data2 - data1 em dias
        // let diff = data2.diff(data1, 'days');
        let diff = Math.abs(data2.diff(data1, 'days'));

        return diff;
    }
    renderBotaoSalvarProblema() {
        if (this.props.descricaoProblemaEstatico !== '') {
            return (
                <ButtonSalvarComentario onClick={() => {
                    this._toggleModalVisibleDescricaoProblema();
                    this.props.cadastraProblemaEstatico(this.props.descricaoProblemaEstatico, this.props.problemasSelecionado);
                }} backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cadastrar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cadastrar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }
    renderBotaoSalvarResposta() {
        if (this.props.descricaoRespostaPadrao !== '' && this.props.mensagemRespostaPadrao !== '') {
            return (
                <ButtonSalvarComentario onClick={() => {
                    this._toggleModalVisibleRespostaPadrao();
                    this.props.cadastraRespostaPadrao(this.props.descricaoRespostaPadrao, this.props.mensagemRespostaPadrao);
                }} backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cadastrar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cadastrar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }
    renderBotaoSalvarRespostaAlterar() {
        if (this.props.descricaoRespostaPadraoAlterar !== '' && this.props.mensagemRespostaPadraoAlterar !== '') {
            return (
                <ButtonSalvarComentario onClick={() => {
                    this._toggleModalVisibleRespostaPadraoAlterar();
                    this.props.alterarRespostaPadrao(this.props.descricaoRespostaPadraoAlterar, this.props.mensagemRespostaPadraoAlterar, this.state.keyRespPadraoAlterar);
                }} backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Alterar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Alterar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }

    renderRangerSliderSegunda(diaSemana, horarioOperacaoSemana, horarioOperacaoSemana2, modificaHora, modificaHora2) {
        if (diaSemana == true) {
            if (this.props.switchUtilizar2HorariosChat == true) {
                return (
                    <div style={{ display: 'flex', flex: 1, width: 700, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flex: 1, width: 300 }}>
                            <TimeRangeSlider
                                disabled={!this.props.switchUtilizarChat}
                                draggableTrack={true}
                                format={24}
                                maxValue={"23:59"}
                                minValue={"00:01"}
                                name={"time_range"}
                                onChangeStart={(time) => this.changeStartHandler(time)}
                                onChangeComplete={(time) => this.changeCompleteHandler(time)}
                                onChange={(time) => modificaHora(time)}
                                step={15}
                                value={horarioOperacaoSemana} />
                        </div>
                        <p style={{ fontSize: 16, fontWeight: 'bold', color: this.props.corLetraDescricaoDosCampos, marginLeft: 30, marginRight: 30 }}> às </p>
                        <div style={{ display: 'flex', flex: 1, width: 300, flexDirection: 'column', marginTop: -22 }}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                                {diaSemana == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({horarioOperacaoSemana2.start} - {horarioOperacaoSemana2.end})</span>}
                                {diaSemana == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            <TimeRangeSlider
                                disabled={!this.props.switchUtilizarChat}
                                draggableTrack={true}
                                format={24}
                                maxValue={"23:59"}
                                minValue={"00:01"}
                                name={"time_range"}
                                onChangeStart={(time) => this.changeStartHandler(time)}
                                onChangeComplete={(time) => this.changeCompleteHandler(time)}
                                onChange={(time) => modificaHora2(time)}
                                step={15}
                                value={horarioOperacaoSemana2} />
                        </div>
                    </div>
                )
            } else {


                return (
                    <div style={{ display: 'flex', flex: 1, width: 700, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', flex: 1, width: 300, }}>
                            <TimeRangeSlider
                                disabled={!this.props.switchUtilizarChat}
                                draggableTrack={true}
                                format={24}
                                maxValue={"23:59"}
                                minValue={"00:01"}
                                name={"time_range"}
                                onChangeStart={(time) => this.changeStartHandler(time)}
                                onChangeComplete={(time) => this.changeCompleteHandler(time)}
                                onChange={(time) => modificaHora(time)}
                                step={15}
                                value={horarioOperacaoSemana} />
                        </div>
                    </div>
                )
            }
        } else {
            return null
        }
    }
    renderConteudoModalConnectWhatsapp() {
        let qrCode = this.props.configWhatsapp.length > 0 ? this.props.configWhatsapp[0].qrCode : '';
        let statusConnect = this.props.configWhatsapp.length > 0 ? this.props.configWhatsapp[0].statusConnect : '';
        if (statusConnect === 'autocloseCalled' || statusConnect === 'browserClose' || this.state.errorConnectarContainer === true) {
            return (
                <>
                    <img src={qrcodeimage} alt="QR CODE" style={{ height: 200, width: 200, opacity: 0.3 }} />
                    <div onClick={() => {
                        if (this.props.premium == 0) {
                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                        } else {
                            /*      if (this.props.dataCompra != '' && this.props.dataValidoAte != '' && this.props.premium == 1) { */
                            this.setState({ infoConectadoOk: false });
                            let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);


                            if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {

                                let dataAtual = this.retornaDataAtual();
                                let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);

                                let diasUsados = diferencaDia - diasRestantesVersaoGratis;

                                if (diasUsados > 7) {
                                    toastr.error('Periodo Teste Esgotou', 'O período teste de 7 dias EXPIROU, Assine a Versão Premium para utilizar este recurso!');
                                } else {
                                    this.somenteConectarContainer();
                                }
                            } else {

                                this.somenteConectarContainer();
                            }

                            /* } */
                        }

                    }} style={{ display: 'flex', height: 140, width: 140, borderRadius: '50%', backgroundColor: '#00BEA4', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', position: 'absolute', center: 0, flexDirection: 'column' }}>
                        <i className="icon-refresh-arrow" style={{ fontSize: 22, color: '#FFF', marginBottom: 7 }} />
                        <p style={{ fontSize: 16, color: '#FFF' }}> Gerar QR CODE</p>
                    </div>
                </>
            )

        } else if (this.state.criandoContainer === true || qrCode === '') {
            return (
                <>
                    <p style={{ fontSize: 19, color: '#000' }}><b>Gerando QR CODE </b><br></br>Por favor Aguarde ...</p>
                    <HashLoader
                        css={{ marginTop: 15 }}
                        size={60}
                        color={'#00BEA4'}
                        loading
                    />
                </>
            )
        } else {
            return (
                <div>
                    <img src={qrCode} alt="QR CODE" style={{ height: 200, width: 200 }} />
                </ div>
            )
        }

    }
    renderConteudoTelaConectarText() {

        if (this.props.configWhatsapp.length === 0 ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'browserClose' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'autocloseCalled' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'desconnectedMobile' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'deleteToken' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'qrReadFail' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === '' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'connecting' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isLogout' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'tokenRemoved' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'qrReadFail' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isDisconnected' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'serverDisconnected') {
            return (<TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Conecte seu Whatsapp para Começar a Atender</TextPermitir>
            )
        } else {
            return (<TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}><span style={{ color: '#00FF7F' }}><b>CONECTADO</b></span></TextPermitir>
            )
        }
    }

    renderConteudoTelaConectar() {
        const CheckBoxWrapper = styled.div`
position: relative;
margin-left:10px;
`;
        const CheckBoxLabel = styled.label`
position: absolute;
top: 0;
left: 0;
width: 42px;
height: 23px;
border-radius: 15px;
background: ${this.props.coresLetraSecundario};
cursor: pointer;
&::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: 3px;
  background: #ffffff;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
`;
        const CheckBox = styled.input`
opacity: 0;
z-index: 1;
border-radius: 15px;
width: 42px;
height: 26px;
&:checked + ${CheckBoxLabel} {
  background: #00FF7F;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-left: 21px;
    transition: 0.2s;
  }

}
&:disabled + ${CheckBoxLabel}{
    background: ${darken(0.2, '#00FF7F')};
}  
`;
        const CheckBox2 = styled.input`
opacity: 0;
z-index: 1;
border-radius: 15px;
width: 42px;
height: 26px;
&:checked + ${CheckBoxLabel} {
background: #00FF7F;
&::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-left: 21px;
  transition: 0.2s;
}
}  &:disabled + ${CheckBoxLabel}{
  background: ${darken(0.2, '#9fadb7')};
  cursor:no-drop;
}
`;
        if (this.props.configWhatsapp.length === 0 ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'browserClose' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'autocloseCalled' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'desconnectedMobile' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'deleteToken' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'qrReadFail' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === '' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'connecting' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isLogout' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'tokenRemoved' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'qrReadFail' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isDisconnected' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'serverDisconnected') {
            return (
                <>

                    <ContainerVersaoMd >
                        <TextPermitir data-tip="Ao marcar está opção, você terá que conectar seu WhatsApp com a versão Beta (Multi Device) ATIVA!" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Multi Dispositivo(Versão BETA)<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                        <CheckBoxWrapper>
                            <CheckBox2 id="checkbox56" type="checkbox" checked={this.props.utilizaVersaoBETA} onChange={(value) => {
                                /*  if (this.props.configWhatsapp.length > 0) {
                                     this.setState({ modalRemoverConexaoVersaoBeta: true })
                                     //tem conexao com whatsapp, mostrar que precisara fazer uma nova conexao para ativar o beta
 
                                 } else {
 
                                     this.props.alteraValorSwitchUtilizaVersaoBETA(value.target.checked)
                                 } */
                            }} />
                            <CheckBoxLabel htmlFor="checkbox56" />
                        </CheckBoxWrapper>

                    </ContainerVersaoMd>
                    <DivConfiguracoes style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ContainerNumeroWpp>
                            <img src={whatsappbackground} alt="whatsapp" style={{
                                height: 250, width: 250,
                            }} />

                            <ButtonConectar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                } else {
                                    /*      if (this.props.dataCompra != '' && this.props.dataValidoAte != '' && this.props.premium == 1) { */
                                    this.setState({ infoConectadoOk: false });
                                    let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);


                                    if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {

                                        let dataAtual = this.retornaDataAtual();
                                        let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);

                                        let diasUsados = diferencaDia - diasRestantesVersaoGratis;

                                        if (diasUsados > 7) {
                                            toastr.error('Periodo Teste Esgotou', 'O período teste de 7 dias EXPIROU, Assine a Versão Premium para utilizar este recurso!');
                                        } else {

                                            this.toggleModalConectarWhatsapp();
                                            if (this.props.configWhatsapp.length > 0) {
                                                this.somenteConectarContainer();
                                            }/*  else if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged') {
                                                //nao faz nada, ja esta em periodo de exibicao do token
                                            } */ else {
                                                this.criaContainerVenom();
                                            }
                                        }
                                    } else {

                                        this.toggleModalConectarWhatsapp();
                                        if (this.props.configWhatsapp.length > 0) {
                                            this.somenteConectarContainer();
                                        }/*  else if (this.props.configWhatsapp.length > 0) {
                                            //nao faz nada, ja esta em periodo de exibicao do token
                                        } */ else {
                                            this.criaContainerVenom();
                                        }
                                    }

                                    /* } */
                                }



                            }}>
                                Conectar
                            </ButtonConectar>
                        </ContainerNumeroWpp>
                        {this.props.configWhatsapp.length > 0 && <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 17, textAlign: 'center' }}>Se tiver algum problema para autenticar, exclua o token</p>
                            <ButtonRemoveToken backGroundDinamico={'#EC6A69'} corLetraBotao={'#FFF'} type="button" onClick={() => { this.toggleModalExcluiTokenWhatsapp(); this.desconnectarContainerVenom(false, this.props.utilizaVersaoBETA) }}>Excluir Token</ButtonRemoveToken>
                        </div>}
                    </DivConfiguracoes>
                </>
            )
        } else {
            return (
                <>

                    <ContainerVersaoMd >
                        <TextPermitir data-tip="Ao marcar está opção, você terá que conectar seu WhatsApp com a versão Beta (Multi Device) ATIVA!" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Multi Dispositivo(Versão BETA)<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                        <CheckBoxWrapper>
                            <CheckBox2 id="checkbox56" type="checkbox" checked={this.props.utilizaVersaoBETA} onChange={(value) => {
                                /*   if (this.props.configWhatsapp.length > 0) {
                                      //tem conexao com whatsapp, mostrar que precisara fazer uma nova conexao para ativar o beta
                                      this.setState({ modalRemoverConexaoVersaoBeta: true })
  
                                  } else {
                                      this.props.alteraValorSwitchUtilizaVersaoBETA(value.target.checked)
                                  } */
                            }} />
                            <CheckBoxLabel htmlFor="checkbox56" />
                        </CheckBoxWrapper>

                    </ContainerVersaoMd>
                    <DivConfiguracoes style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{
                            display: 'flex',
                            height: 300,
                            width: 250,
                            backgroundColor: '#DEEFFE',
                            flexDirection: 'column',
                            borderRadius: 5,
                            flex: 1
                        }}>
                            <div style={{ display: 'flex', height: 250, width: 250, alignItems: 'center', justifyContent: 'center', }}>
                                <img src={approved} alt="whatsapp" style={{
                                    height: 150, width: 150,
                                }} />
                            </div>
                            <ButtonConectar backGroundDinamico={'#EC6A69'} corLetraBotao={'#FFF'} type="button" onClick={() => {
                                this.toggleModalDesconectarWhatsapp();
                            }}>
                                Desconectar
                            </ButtonConectar>
                        </div>
                        {this.props.configWhatsapp.length > 0 && <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 17, textAlign: 'center' }}>Se tiver algum problema para autenticar, exclua o token</p>
                            <ButtonRemoveToken backGroundDinamico={'#EC6A69'} corLetraBotao={'#FFF'} type="button" onClick={() => { this.toggleModalExcluiTokenWhatsapp(); this.desconnectarContainerVenom(false, this.props.utilizaVersaoBETA) }}>Excluir Token</ButtonRemoveToken>
                        </div>}
                    </DivConfiguracoes>
                </>
            )
        }
    }
    renderOptions(props, option, snapshot, className) {

        let key = option.value;
        return (
            <div {...props} >

                <div style={{ display: 'flex', flexDirection: 'row', height: 35 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '86%', alignItems: 'center', justifyContent: 'center', height: 35 }}>
                        <DivTruncarTexto>
                            <TextHeader>{option.name}</TextHeader>
                        </DivTruncarTexto>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '14%', alignItems: 'center', justifyContent: 'space-around', height: 35 }}>

                        <IconeExcluirRespPadrao onClick={() => {
                            this.props.excluirRespostaPadrao(key, this.props.respostasSelecionadas);
                        }} className="icon-rubbish-bin" colorDinamico={'#EC6A69'} />
                        <IconeExcluirRespPadrao2 onClick={() => {
                            this.alterarRespostaPadrao(option, key)
                        }} className="icon-editar" colorDinamico={'#1d1d1d'} />
                    </div>
                </div>
            </div>
        );
    }

    /* renderOptions(props, option, snapshot, className) {

         
         

        return (
                <div onClick={() => {}} style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <TextHeader corLetraDinamica ={"#000"}>{props}</TextHeader>
                    </div>
                    <div style={{ display: 'flex', alignItems:'center', flexDirection: 'row',justifyContent:'space-around', width: '15%',zIndex:999 }}>
                        <IconeExcluirRespPadrao onClick={() => {alert("Excluir")}} className="icon-rubbish-bin" colorDinamico={'#EC6A69'} />
                        <IconeExcluirRespPadrao onClick={() => {alert("Alterar")}} className="icon-editar" colorDinamico={'#000'} />
                    </div>
                </div>
        );
    } */
    renderLoadingConfiguracao() {

        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        
        }
        &:disabled + ${CheckBoxLabel}{
            background: ${darken(0.2, '#00FF7F')};
        }  
      `;
        const CheckBox2 = styled.input`
      opacity: 0;
      z-index: 1;
      border-radius: 15px;
      width: 42px;
      height: 26px;
      &:checked + ${CheckBoxLabel} {
        background: #00FF7F;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin-left: 21px;
          transition: 0.2s;
        }
      }  &:disabled + ${CheckBoxLabel}{
          background: ${darken(0.2, '#9fadb7')};
          cursor:no-drop;
      }
    `;

        if (this.props.loadingConfigAtendimentos) {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                    <ContainerCfg backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Atendimentos</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                        </DivConfiguracoes>

                    </ContainerCfg>
                    <ContainerCfg backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Avaliação no Atendimento</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>
                            <ContainerPermitir>
                                <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            </ContainerPermitir>
                            <DivInputComentarChamado >
                                <TextInputLoading backgroundinamico={this.props.backgroundSegundo}></TextInputLoading>
                            </DivInputComentarChamado>
                        </DivConfiguracoes>

                    </ContainerCfg>
                    <ContainerCfg backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Configuração da Empresa</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>

                            <DivInputComentarChamado >
                                <TextNomeEmpresaLoading backgroundinamico={this.props.backgroundSegundo}></TextNomeEmpresaLoading>
                            </DivInputComentarChamado>
                            <ContainerCores corLetraSecundaria={this.props.coresLetraSecundario}>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                                <TextOpcaoCorLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoCorLoading>
                            </ContainerCores>
                        </DivConfiguracoes>

                    </ContainerCfg>
                    <ContainerCfg backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Notificações</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                        </DivConfiguracoes>

                    </ContainerCfg>
                </Container>
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                    <ContainerCfg style={{}} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Atendimentos</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>


                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao marcar está opção, obrigatóriamente o atendente verá apenas seus próprios chamados<br/> e os chamados ABERTOS provenientes dos setores á ele vinculado<br/>(esses chamados serão visto na aba de CHAMADO ABERTO, enquanto estiver com status ABERTO),<br/> exceto o atendente CONTA PRINCIPAL este sempre verá todos os chamados.<br/> Já no app DropDesk - Cliente o cliente que está abrindo chamado será obrigado a informar um setor." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Agrupar atendente á SETORES(Departamentos)<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox3" type="checkbox" checked={this.props.agruparAtendenteSetor} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            if (value.target.checked == true) {
                                                this.props.alteraAtendenteVeApenasProprioChamado(value.target.checked)
                                                this.props.alteraAgrupaAtendenteSetor(value.target.checked)
                                            } else {
                                                this.props.alteraAgrupaAtendenteSetor(value.target.checked)
                                                this.props.alteraAtendenteVeApenasProprioChamado(value.target.checked)
                                            }
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox3" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao marcar está opção os ATENDENTES da sua equipe irão visualizar apenas os próprios chamados<br/>  ou seja chamados atribuidos á ele, chamado com o atendente 'Não Informado' não será possível visualizar.<br/>  Exceto o atendente(Conta Principal) este verá todos os chamados sempre." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Atendente visualizar apenas o próprio chamado<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.agruparAtendenteSetor} id="checkbox2" type="checkbox" checked={this.props.atendenteVeApenasProprioChamado} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            this.props.alteraAtendenteVeApenasProprioChamado(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox2" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            {this.props.agruparAtendenteSetor == true && this.props.isAdmin == true && <ContainerPermitir>
                                <TextPermitir data-tip="Está opção terá efeito somente na sua CONTA<br/> Ao marcar está opção você(ADM) verá apenas<br/>os chamados dos SETORES que você estiver vinculado" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Visualizar Chamados Apenas do Setor Vinculado Mesmo Sendo ADM<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox33" type="checkbox" checked={this.props.switchVisualizaChamadosDosSetoresVinculadosADM} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            //this.props.modificaSwitchAdmVisualizaApenasChamadoSetor(value.target.checked)
                                            if (value.target.checked == true) {
                                                this.setState({ modalAtencaoAdmChamadoSetor: true });
                                            } else {
                                                this.props.modificaSwitchAdmVisualizaApenasChamadoSetor(value.target.checked)
                                            }
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox33" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>}
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Permitir ao cliente avaliar atendimento?</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox5" type="checkbox" checked={this.props.switchMEnsagemAvaliacao} onChange={(value) => { this.props.alteraValorSwitchConfigAvaliacao(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox5" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao marcar está opção será OBRIGATÓRIO atribuir um ATENDENTE no chamado,<br/> para poder prosseguir com o atendimento normalmente. Caso não atribuir o atendente ao chamado,<br/>  não será possível fazer nenhuma ação." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Obrigatório atribuir atendente no atendimento<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox" type="checkbox" checked={this.props.switchObrigatorioAtribuirAtendenteAtendimento} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            this.props.alteraValorSwitchConfigAtendimento(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao marcar está opção será OBRIGATÓRIO descrever a resolução do Chamado<br/> Ao finalizar um atendimento" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Obrigatório descrever solução do Atendimento<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkboxModalResolucao" type="checkbox" checked={this.props.obrigatorioInformarResolucaoChamadoConfig} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            this.props.modificaObrigatorioResolucaoChamado(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkboxModalResolucao" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao marcar está opção será OBRIGATÓRIO descrever o motivo de Cancelamento do Chamado<br/> Ao cancelar um atendimento" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Obrigatório descrever Motivo Cancelamento do Atendimento<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkboxMotivoCancelamento" type="checkbox" checked={this.props.obrigatorioInformarMotivoCancelamentoConfig} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            this.props.modificaMotivoCancelamentoConfig(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkboxMotivoCancelamento" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>


                            <ContainerPermitir >
                                <TextPermitir data-tip="Respostas Padrões para ser utilizadas nos chamados / atendimento online(Chat)" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Respostas Padrões<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <div style={{ display: 'flex', width: 420 }}>
                                    <ContainerSelectSearch>
                                        <SelectSearch

                                            renderValue={(valueProps) =>
                                                <DivInputSearch >
                                                    <InputSearch

                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} >

                                                    </InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            renderOption={(props, option, snapshot, className) => this.renderOptions(props, option, snapshot, className)}
                                            value={this.props.respostasSelecionadas}
                                            options={this.props.respostasPadroes}
                                            // multiple
                                            closeOnSelect={false}
                                            // printOptions="on-focus"
                                            onChange={(value) => this.props.modificaRespostaSelecionada(value)}
                                            placeholder={this.props.respostasPadroes.length == 0 ? `Carregando respostas...` : "Marque as que deseja utilizar"}
                                            search />
                                    </ContainerSelectSearch>
                                    {this.props.loadingBotaoNovaResposta == true &&
                                        <ClipLoader
                                            css={{}}
                                            size={30}
                                            color={this.props.corBotaoPadrao}
                                            loading
                                        />
                                    }
                                    {this.props.loadingBotaoNovaResposta == false &&
                                        <BotaoAdd data-tip="Cadastrar nova resposta" data-for="tip-right" backgroundDinamico={this.props.corBotaoPadrao} onClick={() => {
                                            if (this.props.premium == 0) {
                                                toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                            } else {
                                                this._toggleModalVisibleRespostaPadrao()
                                            }
                                        }}>
                                            <i className="icon-add2-1" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                        </BotaoAdd>}
                                </div>
                            </ContainerPermitir>

                        </DivConfiguracoes>
                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalVisibleRespostaPadrao} onClose={() => { }} center>
                            <ContainerModalRespostaPadrao backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cadastro Respostas Padrões</TextNovoAtendimento>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalVisibleRespostaPadrao() }} className="icon-close" />
                                </ContainerIconeFecharModal>

                                <ContainerRespostas>
                                    <ContainerInputRespostaPadrao>
                                        <DivInputComentarChamado >
                                            <p style={{ fontSize: 16, color: this.props.corLetraDescricaoDosCampos }}>Descrição:</p>
                                            <InputDescricaoTituloResposta
                                                onChange={(value) => { this.props.modificaDescricaoRespostaPadrao(value.target.value) }}
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                value={this.props.descricaoRespostaPadrao}
                                                corLetraSecundaria={this.props.coresLetraSecundario}
                                                placeholder="Digite a descrição da resposta padrão"
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                            />
                                        </DivInputComentarChamado>
                                        <DivInputComentarChamado >
                                            <p style={{ fontSize: 16, color: this.props.corLetraDescricaoDosCampos }}>Mensagem:</p>
                                            <InputDescricaoChamado

                                                type="textarea"
                                                onChange={(value) => { this.props.modificaMensagemRespostaPadrao(value.target.value) }}
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                                value={this.props.mensagemRespostaPadrao}
                                                corLetraSecundaria={this.props.coresLetraSecundario}
                                                placeholder="Digite a resposta padrão"
                                            />
                                        </DivInputComentarChamado>
                                    </ContainerInputRespostaPadrao>
                                    <DivBotaoSalvarResposta>
                                        {this.renderBotaoSalvarResposta()}

                                    </DivBotaoSalvarResposta>
                                </ContainerRespostas>
                            </ContainerModalRespostaPadrao>
                        </Modal>
                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalVisibleRespostaPadraoAlterar} onClose={() => { }} center>
                            <ContainerModalRespostaPadrao backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Alterar Respostas Padrões</TextNovoAtendimento>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalVisibleRespostaPadraoAlterar() }} className="icon-close" />
                                </ContainerIconeFecharModal>

                                <ContainerRespostas>
                                    <ContainerInputRespostaPadrao>
                                        <DivInputComentarChamado >
                                            <p style={{ fontSize: 16, color: this.props.corLetraDescricaoDosCampos }}>Descrição:</p>
                                            <InputDescricaoTituloResposta
                                                onChange={(value) => { this.props.modificaDescricaoRespostaPadraoAlterar(value.target.value) }}
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                value={this.props.descricaoRespostaPadraoAlterar}
                                                corLetraSecundaria={this.props.coresLetraSecundario}
                                                placeholder="Digite a descrição da resposta padrão"
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                            />
                                        </DivInputComentarChamado>
                                        <DivInputComentarChamado >
                                            <p style={{ fontSize: 16, color: this.props.corLetraDescricaoDosCampos }}>Mensagem:</p>
                                            <InputDescricaoChamado

                                                type="textarea"
                                                onChange={(value) => { this.props.modificaMensagemRespostaPadraoAlterar(value.target.value) }}
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                                value={this.props.mensagemRespostaPadraoAlterar}
                                                corLetraSecundaria={this.props.coresLetraSecundario}
                                                placeholder="Digite a resposta padrão"
                                            />
                                        </DivInputComentarChamado>
                                    </ContainerInputRespostaPadrao>
                                    <DivBotaoSalvarResposta>
                                        {this.renderBotaoSalvarRespostaAlterar()}

                                    </DivBotaoSalvarResposta>
                                </ContainerRespostas>
                            </ContainerModalRespostaPadrao>
                        </Modal>
                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalVisibleDescricaoProblema} onClose={() => { }} center>
                            <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cadastro de Problema Pré Definido</TextNovoAtendimento>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalVisibleDescricaoProblema() }} className="icon-close" />
                                </ContainerIconeFecharModal>

                                <ContainerAtendentes>
                                    <ContainerInputEBotaoComent>

                                        <DivInputComentarChamado >
                                            <InputDescricaoChamado
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                                type="textarea"
                                                onChange={(value) => { this.props.modificaDescricaoProblemaEstatico(value.target.value) }}
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                value={this.props.descricaoProblemaEstatico}
                                                corLetraSecundaria={this.props.coresLetraSecundario}
                                                placeholder="Insira o problema Pré Definido"
                                            />
                                        </DivInputComentarChamado>
                                    </ContainerInputEBotaoComent>
                                    <DivBotaoSalvarComentario>
                                        {this.renderBotaoSalvarProblema()}

                                    </DivBotaoSalvarComentario>
                                </ContainerAtendentes>
                            </ContainerModalPrioridadeAtendimento>
                        </Modal>
                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalResetarCores} onClose={() => { }} center>
                            <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Resetar Cores</TextNovoAtendimento>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalResetarcores() }} className="icon-close" />
                                </ContainerIconeFecharModal>

                                <ContainerAtendentes>
                                    <ContainerInputEBotaoComent>

                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}> Deseja Realmente retornar para as cores padrões do DropDesk?</p>
                                    </ContainerInputEBotaoComent>
                                    <DivBotaoSalvarComentario>
                                        <ButtonSalvarComentario onClick={() => {
                                            this._toggleModalResetarcores();
                                            this.props.resetarCores();
                                        }} backgroundBotao={this.props.corBotaoPadrao}>
                                            <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Resetar</TextBotaoSalvarComentar>
                                        </ButtonSalvarComentario>

                                    </DivBotaoSalvarComentario>
                                </ContainerAtendentes>
                            </ContainerModalPrioridadeAtendimento>
                        </Modal>

                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalRemoverConexaoVersaoBeta} onClose={() => { }} center>
                            <ContainerModalAtivarMD backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    {this.props.utilizaVersaoBETA === false && <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Ativar Versão MD BETA</TextNovoAtendimento>}
                                    {this.props.utilizaVersaoBETA === true && <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Remover Versão MD BETA</TextNovoAtendimento>}
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalRemoverConexaoVersaoBeta: false }) }} className="icon-close" />
                                </ContainerIconeFecharModal>
                                {this.state.removendoConexaoParaBeta === false && this.props.utilizaVersaoBETA === false &&
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <div>
                                            <img src={backGroundStopVersaoPremium} alt="foto atencao" style={{ height: 150, width: '100%', }} />
                                        </div>
                                        <ContainerExplicacaoVincular>
                                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Atenção!!! Para ATIVAR a conexão Multi Dispositivo(versão BETA), é necessário excluir sua conexão atual, para que haja uma nova leitura de QR CODE com a versão BETA no APARELHO *HABILITADA*. </TextVincular>
                                        </ContainerExplicacaoVincular>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                                            <ButtonSalvarComentario onClick={() => {
                                                this.setState({ removendoConexaoParaBeta: true })
                                                this.desconnectarContainerVenom(true, this.props.utilizaVersaoBETA)
                                            }} backgroundBotao={this.props.corBotaoPadrao}>
                                                <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Excluir</TextBotaoSalvarComentar>
                                            </ButtonSalvarComentario>

                                            <ButtonSalvarComentarioSemComent onClick={() => { this.setState({ modalRemoverConexaoVersaoBeta: false }) }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                                                <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cancelar</TextBotaoSalvarComentar>
                                            </ButtonSalvarComentarioSemComent>
                                        </div>
                                    </div>
                                }
                                {this.state.removendoConexaoParaBeta === false && this.props.utilizaVersaoBETA === true &&
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <div>
                                            <img src={backGroundStopVersaoPremium} alt="foto atencao" style={{ height: 150, width: '100%', }} />
                                        </div>
                                        <ContainerExplicacaoVincular>
                                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Atenção!!! Para DESATIVAR a conexão Multi Dispositivo(versão BETA), é necessário excluir sua conexão atual, para que haja uma nova leitura de QR CODE com a versão BETA no APARELHO *DESABILITADA*. </TextVincular>
                                        </ContainerExplicacaoVincular>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                                            <ButtonSalvarComentario onClick={() => {
                                                this.setState({ removendoConexaoParaBeta: true })
                                                this.desconnectarContainerVenom(true, this.props.utilizaVersaoBETA)
                                            }} backgroundBotao={this.props.corBotaoPadrao}>
                                                <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Excluir</TextBotaoSalvarComentar>
                                            </ButtonSalvarComentario>

                                            <ButtonSalvarComentarioSemComent onClick={() => { this.setState({ modalRemoverConexaoVersaoBeta: false }) }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                                                <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cancelar</TextBotaoSalvarComentar>
                                            </ButtonSalvarComentarioSemComent>


                                        </div>
                                    </div>
                                }
                                {this.state.removendoConexaoParaBeta === true && <ContainerAtendentes>



                                    <>
                                        <p style={{ fontSize: 19, color: this.props.coresLetraPadrao }}><b>Removendo Conexão</b><br></br>Por favor Aguarde ...</p>
                                        <HashLoader
                                            css={{ marginTop: 15 }}
                                            size={60}
                                            color={'#00BEA4'}
                                            loading
                                        />
                                    </>

                                </ContainerAtendentes>}
                            </ContainerModalAtivarMD>
                        </Modal>
                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalExcluirToken} onClose={() => { }} center>
                            <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Excluir Token</TextNovoAtendimento>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.toggleModalExcluiTokenWhatsapp(); }} className="icon-close" />
                                </ContainerIconeFecharModal>

                                <ContainerAtendentes>



                                    <>
                                        <p style={{ fontSize: 19, color: this.props.coresLetraPadrao }}><b>Excluindo TOKEN</b><br></br>Por favor Aguarde ...</p>
                                        <HashLoader
                                            css={{ marginTop: 15 }}
                                            size={60}
                                            color={'#00BEA4'}
                                            loading
                                        />
                                    </>

                                </ContainerAtendentes>
                            </ContainerModalPrioridadeAtendimento>
                        </Modal>
                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalDesconectarWhatsapp} onClose={() => { }} center>
                            <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Desconectar Número WhatsApp</TextNovoAtendimento>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.toggleModalDesconectarWhatsapp(); }} className="icon-close" />
                                </ContainerIconeFecharModal>

                                <ContainerAtendentes>
                                    {this.state.desconectandoContainer === false && <> <ContainerInputEBotaoComent>

                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}> Deseja Realmente Desconectar seu WhatsApp?</p>
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}> Você não irá conseguir atender via WhatsApp pelo DropDesk.</p>
                                    </ContainerInputEBotaoComent>
                                        <DivBotaoSalvarComentario>
                                            <ButtonNaoCancelarAtendimento onClick={() => { this.toggleModalDesconectarWhatsapp(); }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                                <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                            </ButtonNaoCancelarAtendimento>
                                            <ButtonCancelarAtendimento onClick={() => { this.desconnectarContainerVenom(false, this.props.utilizaVersaoBETA) }} backgroundBotao={this.props.corBotaoPadrao}>
                                                <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Desconectar</TextBotaoCancelarAtendimento>
                                            </ButtonCancelarAtendimento>

                                        </DivBotaoSalvarComentario></>}

                                    {this.state.desconectandoContainer === true &&
                                        <>
                                            <p style={{ fontSize: 19, color: this.props.coresLetraPadrao }}><b>Desconectando</b><br></br>Por favor Aguarde ...</p>
                                            <HashLoader
                                                css={{ marginTop: 15 }}
                                                size={60}
                                                color={'#00BEA4'}
                                                loading
                                            />
                                        </>
                                    }
                                </ContainerAtendentes>
                            </ContainerModalPrioridadeAtendimento>
                        </Modal>
                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalConnectWhatsapp} onClose={() => { }} center>
                            <ContainerModalConectarWpp backgroundDinamico={'#EFF8FE'}>
                                <ContainerIconeFecharModal backgroundDinamico={'#00BEA4'}>
                                    <TextNovoAtendimento corLetraDinamico={'#FFF'}>Connectar Número WhastApp</TextNovoAtendimento>
                                    <IconeFechar backgroundDinamico={'#FFF'} onClick={() => {
                                        if (this.state.infoConectadoOk === true) {
                                            this.myRef.scrollIntoView(); this.setState({ modalConnectWhatsapp: false }); this.props.alteraValorSwitchUtilizarChat(true)

                                        } else {
                                            this.toggleModalConectarWhatsapp()
                                        }
                                    }} className="icon-close" />
                                </ContainerIconeFecharModal>
                                {this.state.infoConectadoOk === false && <> <div style={{ marginTop: 10, marginLeft: 10 }}>
                                    <p style={{ color: '#000', fontSize: 15 }}>1 - Abra o Whatsapp no seu Celular</p>
                                    <p style={{ color: '#000', fontSize: 15 }}>2 - Toque em <b>"Menu"</b> <i className="icon-menu3" style={{ fontSize: 14, color: '#000' }} /> ou <b>"Configurações"</b>  <i className="icon-settings" style={{ fontSize: 14, color: '#000' }} /> e selecione<b>"WhatsApp Web"</b></p>
                                    <p style={{ color: '#000', fontSize: 15 }}>3 - Aponte o seu celular para essa tela para capturar o <b>código</b></p>
                                </div>
                                    <ContainerAtendentes>

                                        {this.renderConteudoModalConnectWhatsapp()}

                                    </ContainerAtendentes>
                                    <div style={{ marginTop: 10, marginLeft: 10, marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ color: '#000', fontSize: 17 }}>Ao registrar seu número, você concorda com os </p>
                                        <a style={{ marginLeft: 5 }} href="https://www.whatsapp.com/legal/terms-of-service?lang=pt_br" target="_blank"><p onClick={() => { }} style={{ color: '#39a3f4', fontSize: 17, textDecoration: 'underline', cursor: 'pointer' }}>Termos de banimento do WhatsApp</p></a>
                                    </div></>}
                                {this.state.infoConectadoOk === true && <> <div style={{ marginTop: 20 }}>
                                    <p style={{ color: '#000', fontSize: 20, textAlign: 'center' }}>Parabéns, conectado com <span style={{ color: '#00BEA4', fontSize: 22 }}><b>Sucesso!</b></span></p>
                                </div>
                                    <ContainerAtendentes>

                                        <img src={approved} alt="conectado" style={{ height: 200, width: 200 }} />

                                    </ContainerAtendentes>
                                    <div style={{ marginTop: 10, marginLeft: 10, marginBottom: 40, display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ color: '#000', fontSize: 17 }}><b>IMPORTANTE:</b> Configure seu Horário de Operação em <b>"Configuração do Chat"</b> ou </p>
                                        <p onClick={() => { this.myRef.scrollIntoView(); this.setState({ modalConnectWhatsapp: false }); this.props.alteraValorSwitchUtilizarChat(true) }} style={{ color: '#39a3f4', fontSize: 17, textDecoration: 'underline', cursor: 'pointer', textAlign: 'center' }}><b>Clique aqui para Configurar</b></p>
                                    </div></>}
                            </ContainerModalConectarWpp>
                        </Modal>

                        <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAtencaoAdmChamadoSetor} onClose={() => { }} center>
                            <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                                <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Atenção</TextNovoAtendimento>
                                    <div>
                                        <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                            this.setState({ modalAtencaoAdmChamadoSetor: false })
                                        }} className="icon-close" />
                                    </div>
                                </ContainerIconeFecharModal>
                                <ContainerImagemVincularEmpresa>
                                    <img src={backGroundStopVersaoPremium} alt="foto atencao" style={{ height: 130, width: '100%', }} />
                                </ContainerImagemVincularEmpresa>

                                <ContainerExplicacaoVincular>
                                    <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Atenção!!! Ao MARCAR esta opção, Você deixará de visualizar todos os chamados e irá visualizar somente os chamados dos setores que você está vinculado, está OPÇÃO ficará vigente apenas na sua CONTA. </TextVincular>
                                </ContainerExplicacaoVincular>
                                <ContainerBotaoAlterar>
                                    <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                        this.setState({ modalAtencaoAdmChamadoSetor: false });


                                        this.props.modificaSwitchAdmVisualizaApenasChamadoSetor(true)

                                    }}>
                                        MARCAR
                                    </ButtonVincular>
                                </ContainerBotaoAlterar>
                            </ContainerModal>
                        </Modal>
                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarConfigAtendimento == false &&
                                <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                    this.props.alteraConfiguracaoAtendimento(this.props.switchObrigatorioAtribuirAtendenteAtendimento, this.props.agruparAtendenteSetor, this.props.atendenteVeApenasProprioChamado, this.props.respostasPadroes, this.props.respostasSelecionadas, this.props.switchVisualizaChamadosDosSetoresVinculadosADM, this.props.keyUsuarioLogado,
                                        this.props.obrigatorioInformarResolucaoChamadoConfig, this.props.switchMEnsagemAvaliacao, this.props.obrigatorioInformarMotivoCancelamentoConfig
                                    )
                                }}>
                                    Salvar
                                </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarConfigAtendimento == true &&
                                <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={this.props.corBotaoPadrao}
                                    loading
                                />
                            }
                        </ContainerBotaoAlterar>
                    </ContainerCfg>

                    <ContainerCfg style={{}} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabWbb bordaDinamica={this.props.coresLetraSecundario}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img src={wppimage} alt="whatsapp" style={{
                                    height: 20, width: 20, marginRight: 10
                                }} />
                                <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao
                                } > WhatsApp</TextCabecalho>
                            </div>
                            <div>
                                {this.renderConteudoTelaConectarText()}

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 100 }}>
                                <p style={{ color: this.props.backgroundSegundo }}>whatsapp</p>
                            </div>
                        </DivCabWbb>
                        {this.renderConteudoTelaConectar()}
                        <DivConfiguracoesWhatsApp>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TextPermitir data-tip="Limite máximo para o cliente digitar o NOME/SETOR, após este limite o chamado é redirecionado para a Fila de Espera de Todos os Atendentes no menu Chat" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Limite de Espera para o cliente Informar SETOR/NOME<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <div style={{ display: 'flex', width: 250 }}>
                                    <ContainerSelectSearch>
                                        <SelectSearch
                                            disabled={this.props.desativarAssistenteVirtualWpp}
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch

                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            value={this.props.limiteEmEsperaSelecionado}
                                            options={this.props.limitesEmEEspera}
                                            // multiple
                                            closeOnSelect={true}
                                            printOptions="on-focus"
                                            onChange={(value) => this.props.modificaLimiteDeEsperaFilaChat(value)}
                                            placeholder={this.props.respostasPadroes.length == 0 ? `Carregando...` : "Marque o limite"}
                                            search />
                                    </ContainerSelectSearch>

                                </div>
                            </div>
                            <ContainerPermitir >
                                <TextPermitir data-tip="Ao marcar está opção, os chamados serão redirecionados para os Atendentes do Setor Selecionado abaixo<br/> Devido ao cliente não selecionar o SETOR/NOME após o tempo limite se esgotar" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Redirecionar Atendimentos p/ Setor Após Tempo Limite<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox2 disabled={this.props.desativarAssistenteVirtualWpp} id="checkbox55" type="checkbox" checked={this.props.enviaAtendimentoParaSetorAposLimite} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            this.props.alteraValorSwitchEnviaAtendimentoParaSetorAposLimite(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox55" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                        </DivConfiguracoesWhatsApp>

                        {this.props.enviaAtendimentoParaSetorAposLimite == true &&
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ width: 250, marginRight: 32 }}>
                                    {/*     <ContainerSelecionaSetorAposLimite corBordaDinamica={this.props.coresLetraSecundario}> */}
                                    <ContainerSelectSearch >
                                        <p style={{ marginLeft: 5, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Selecione o Setor:</p>
                                        <SelectSearch

                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        disabled={this.props.desativarAssistenteVirtualWpp}
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            search={this.props.setoresParaLimite.length == 0 ? false : true}
                                            closeOnSelect={true}
                                            printOptions="on-focus"
                                            multiple={false}
                                            value={this.props.keySetorSelecionadoParaEnviarAtendimentoAposLimite}
                                            onChange={(value) => {
                                                this.props.modificaKeySetorSelecionadoParaEnviarAtendimentoAposLimiteExport(value);
                                            }}
                                            options={this.props.setoresParaLimite}
                                            placeholder={this.props.setoresParaLimite.length == 0 ? `Nenhum Setor cadastrado!` : "Selecione o Setor"}
                                        />

                                    </ContainerSelectSearch>

                                    {/*  </ContainerSelecionaSetorAposLimite> */}
                                </div>
                            </div>}

                        {/*   <DivConfiguracoesWhatsApp>
                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao desmarcar está opção, não pedirá o NOME do Cliente no primeiro contato dele com a sua empresa no WhatsApp, consequentemente será salvo com o número no lugar do nome" data-for="tip-top"  corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Solicitar NOME do CLIENTE no primeiro contato?<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox2 disabled={this.props.desativarAssistenteVirtualWpp} id="checkbox29" type="checkbox" checked={this.props.solicitarNomeClientePrimeirocontato} onChange={(value) => { this.props.modificaSolicitarNomeClientePrimeirocontato(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox29" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                        </DivConfiguracoesWhatsApp> */}
                        <DivConfiguracoesWhatsApp>
                            <ContainerPermitir >
                                <TextPermitir data-tip="Ao marcar está opção, quando o cliente for transferido de setor, chegará uma mensagem informando pra qual setor ele foi transferido." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Enviar mensagem de Transferência de Setor<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox2 disabled={this.props.desativarAssistenteVirtualWpp} id="checkbox57" type="checkbox" checked={this.props.enviarMensagemTransSetorWppConfig} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        } else {
                                            this.props.modificaEnviarMensagemTransSetorWppConfig(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox57" />
                                </CheckBoxWrapper>
                            </ContainerPermitir>
                        </DivConfiguracoesWhatsApp>
                        <DivConfiguracoesWhatsApp>
                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao marcar está opção a Assistente Virtual será desativada, quando cliente entrar em contato, será redirecionado direto para fila de espera" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Desativar Completamente Assistente Virtual(BOT)?<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox2 id="checkbox28" type="checkbox" checked={this.props.desativarAssistenteVirtualWpp} onChange={(value) => {
                                        this.props.modificaDesativarAssistenteVirtualWpp(value.target.checked)
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox28" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                        </DivConfiguracoesWhatsApp>
                        <DivConfiguracoesWhatsApp>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Exibir NOME do Atendente nas mensagens?</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox2
                                        //disabled={this.props.desativarAssistenteVirtualWpp}
                                        id="checkbox27" type="checkbox" checked={this.props.mostrarNomeAtendenteMensagem} onChange={(value) => { this.props.modificaMostrarNomeAtendenteMensagem(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox27" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                        </DivConfiguracoesWhatsApp>
                        <DivConfiguracoesWhatsApp>
                            <ContainerPermitir>
                                <TextPermitir data-tip="Ao marcar está opção o atendente que não seja um ADMIN, irá vê apenas o próprio histórico das conversas, ao carregar mais conversas..." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Atendentes (Não Admin) vê apenas próprio histórico de conversas no menu Chat<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox2 id="checkbox29" type="checkbox" checked={this.props.atendenteVeApenasProprioHistorico} onChange={(value) => {
                                        this.props.modificaAtendenteVeApenasProprioHistorico(value.target.checked)
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox29" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                        </DivConfiguracoesWhatsApp>
                        <DivConfiguracoes>
                            <DivInputComentarChamado >
                                <TextMensagem style={{ marginBottom: 3 }} corLetraDinamica={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Mensagem Bem-Vindo: <IconeInterrogacao data-tip="{{nome_cliente}} representa o Nome do Cliente do Atendimento<br/>{{nome_empresa}} representa o Nome da sua Empresa<br/>{{protocolo}} representa o Número do Atendimento<br/>{{data_abertura}} representa a data de abertura do atendimento<br/>*(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp" data-for="tip-top" colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextMensagem>
                                <InputDescricaoChamado2
                                    disabled={this.props.desativarAssistenteVirtualWpp}
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaMensagemBoasVindasWpp(value.target.value) }}
                                    corLetraDinamico={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.coresLetraPadrao}
                                    value={this.props.mensagemBemVindo}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    placeholder="Digite a Mensagem de Boas Vindas"
                                    corBotaoPadrao={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                        </DivConfiguracoes>
                        <DivConfiguracoes>
                            <DivInputComentarChamado >
                                <TextMensagem style={{ marginBottom: 3 }} corLetraDinamica={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Mensagem Escolha Departamento: <IconeInterrogacao data-tip="{{nome_empresa}} representa o Nome da sua Empresa<br/>*(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp" data-for="tip-top" colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextMensagem>
                                <InputDescricaoChamado2
                                    disabled={this.props.desativarAssistenteVirtualWpp}
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaMensagemEscolhaDepartamento(value.target.value) }}
                                    corLetraDinamico={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.coresLetraPadrao}
                                    value={this.props.mensagemEscolhaDepartamento}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    placeholder="Digite a Mensagem de Escolha de Setor"
                                    corBotaoPadrao={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                        </DivConfiguracoes>
                        <DivConfiguracoes>
                            <DivInputComentarChamado >
                                <TextMensagem style={{ marginBottom: 3 }} corLetraDinamica={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Mensagem Fila Atendimento: <IconeInterrogacao data-tip="{{nome_empresa}} representa o Nome da sua Empresa<br/>*(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp" data-for="tip-top" colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextMensagem>
                                <InputDescricaoChamado2
                                    disabled={this.props.desativarAssistenteVirtualWpp}
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaMensagemFilaAtendimento(value.target.value) }}
                                    corLetraDinamico={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.coresLetraPadrao}
                                    value={this.props.mensagemFilaAtendimento}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    placeholder="Digite a Mensagem de Fila de Atendimento"
                                    corBotaoPadrao={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                        </DivConfiguracoes>
                        <DivConfiguracoes>
                            <DivInputComentarChamado >
                                <TextMensagem style={{ marginBottom: 3 }} corLetraDinamica={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Mensagem Finalização Chamado: <IconeInterrogacao data-tip="{{protocolo}} representa o Número do Atendimento<br/>*(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp" data-for="tip-top" colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextMensagem>
                                <InputDescricaoChamado2
                                    disabled={this.props.desativarAssistenteVirtualWpp}
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaMensagemFinalizacaoChamado(value.target.value) }}
                                    corLetraDinamico={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.coresLetraPadrao}
                                    value={this.props.mensagemFinalizacaoAtendimento}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    placeholder="Digite a Mensagem de Finalização Chamado"
                                    corBotaoPadrao={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                        </DivConfiguracoes>
                        <DivConfiguracoes>
                            <DivInputComentarChamado >
                                <TextMensagem style={{ marginBottom: 3 }} corLetraDinamica={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Mensagem Fora Horário Operação: <IconeInterrogacao data-tip="*(ASTERISTICO)* em volta da palavra representa NEGRITO no WhatsApp" data-for="tip-top" colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextMensagem>
                                <InputDescricaoChamado2
                                    disabled={this.props.desativarAssistenteVirtualWpp}
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaMensagemForaHorarioOperacao(value.target.value) }}
                                    corLetraDinamico={this.props.desativarAssistenteVirtualWpp === true ? '#9fadb7' : this.props.coresLetraPadrao}
                                    value={this.props.mensagemForaHorarioOperacao}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    placeholder="Digite a Mensagem de Fora do Horário de Operação"
                                    corBotaoPadrao={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                        </DivConfiguracoes>
                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarConfigWhatsApp == false &&
                                <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                    this.props.alteraConfiguracaoWhatsApp(this.props.mensagemBemVindo, this.props.mensagemEscolhaDepartamento, this.props.mensagemFilaAtendimento, this.props.mensagemFinalizacaoAtendimento, this.props.mensagemForaHorarioOperacao, this.props.limiteEmEsperaSelecionado,
                                        this.props.enviaAtendimentoParaSetorAposLimite, this.props.keySetorSelecionadoParaEnviarAtendimentoAposLimite,
                                        this.props.mostrarNomeAtendenteMensagem, this.props.desativarAssistenteVirtualWpp, this.props.solicitarNomeClientePrimeirocontato,
                                        this.props.atendenteVeApenasProprioHistorico, this.props.enviarMensagemTransSetorWppConfig)
                                }}>
                                    Salvar
                                </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarConfigWhatsApp == true &&
                                <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={this.props.corBotaoPadrao}
                                    loading
                                />
                            }
                        </ContainerBotaoAlterar>
                    </ContainerCfg>
                    <ContainerCfg ref={(ref) => this.myRef = ref} style={{}} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Configuração do Chat</TextCabecalho>
                        </DivCabecalhoCfg>
                        <ContainerPermitir style={{ padding: 10 }}>
                            <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Utilizar Chat/WhatsApp para atendimento online?</TextPermitir>
                            <CheckBoxWrapper>
                                <CheckBox id="checkbox16" type="checkbox" checked={this.props.switchUtilizarChat} onChange={(value) => { this.props.alteraValorSwitchUtilizarChat(value.target.checked) }} />
                                <CheckBoxLabel htmlFor="checkbox16" />
                            </CheckBoxWrapper>

                        </ContainerPermitir>
                        <ContainerPermitir style={{ padding: 10 }}>
                            <TextPermitir corLetraDinamica={this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Utilizar dois horários de operação diferente no mesmo dia</TextPermitir>
                            <CheckBoxWrapper>
                                <CheckBox2 disabled={!this.props.switchUtilizarChat} id="checkbox17" type="checkbox" checked={this.props.switchUtilizar2HorariosChat} onChange={(value) => { this.props.alteraValorSwitcUtilizar2HorariosChat(value.target.checked) }} />
                                <CheckBoxLabel htmlFor="checkbox17" />
                            </CheckBoxWrapper>

                        </ContainerPermitir>
                        <ContainerPermitir style={{ padding: 10 }}>
                            <TextPermitir corLetraDinamica={this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Selecione seu fuso horário</TextPermitir>
                            <div style={{ display: 'flex', width: 450 }}>
                                <ContainerSelectSearch>
                                    <SelectSearch

                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        disabled={!this.props.switchUtilizarChat} value={this.props.fusoHorarioSelelcionado} options={this.props.fusoHorarios} onChange={(value) => this.props.modificaFusoHorarioSelecionado(value)} placeholder={this.props.fusoHorarios.length == 0 ? `Nenhum fuso horário` : "Selecione"} search />
                                </ContainerSelectSearch>
                            </div>
                        </ContainerPermitir>
                        <ContainerPermitir style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <TextPermitir corLetraDinamica={this.props.switchUtilizarChat === false ? '#9fadb7' : this.props.corLetraDescricaoDosCampos}>Horário de Operação:</TextPermitir>
                        </ContainerPermitir>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', margin: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: 700, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5 }}>
                                <input disabled={!this.props.switchUtilizarChat} type="checkbox" checked={this.props.segundaFeiraChat} onChange={(value) => { this.props.alteraValorCheckSegundaFeira(value.target.checked) }} />
                                <b style={{ fontSize: 13, marginLeft: 7, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>Segunda-feira</b>  {this.props.segundaFeiraChat == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({this.props.horarioOperacaoSegundaFeira.start} - {this.props.horarioOperacaoSegundaFeira.end})</span>}
                                {this.props.segundaFeiraChat == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            {this.renderRangerSliderSegunda(this.props.segundaFeiraChat, this.props.horarioOperacaoSegundaFeira, this.props.horarioOperacaoSegundaFeira2, this.props.modificaHorarioOperacaoSegundaFeira, this.props.modificaHorarioOperacaoSegundaFeira2)}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', margin: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: 700, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5 }}>
                                <input disabled={!this.props.switchUtilizarChat} type="checkbox" checked={this.props.tercaFeiraChat} onChange={(value) => { this.props.alteraValorCheckTercaFeira(value.target.checked) }} />
                                <b style={{ fontSize: 13, marginLeft: 7, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>Terça-feira</b>  {this.props.tercaFeiraChat == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({this.props.horarioOperacaoTercaFeira.start} - {this.props.horarioOperacaoTercaFeira.end})</span>}
                                {this.props.tercaFeiraChat == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            {this.renderRangerSliderSegunda(this.props.tercaFeiraChat, this.props.horarioOperacaoTercaFeira, this.props.horarioOperacaoTercaFeira2, this.props.modificaHorarioOperacaoTercaFeira, this.props.modificaHorarioOperacaoTercaFeira2)}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', margin: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: 700, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5 }}>
                                <input disabled={!this.props.switchUtilizarChat} type="checkbox" checked={this.props.quartaFeiraChat} onChange={(value) => { this.props.alteraValorCheckQuartaFeira(value.target.checked) }} />
                                <b style={{ fontSize: 13, marginLeft: 7, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>Quarta-feira</b>  {this.props.quartaFeiraChat == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({this.props.horarioOperacaoQuartaFeira.start} - {this.props.horarioOperacaoQuartaFeira.end})</span>}
                                {this.props.quartaFeiraChat == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            {this.renderRangerSliderSegunda(this.props.quartaFeiraChat, this.props.horarioOperacaoQuartaFeira, this.props.horarioOperacaoQuartaFeira2, this.props.modificaHorarioOperacaoQuartaFeira, this.props.modificaHorarioOperacaoQuartaFeira2)}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', margin: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: 700, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5 }}>
                                <input disabled={!this.props.switchUtilizarChat} type="checkbox" checked={this.props.quintaFeiraChat} onChange={(value) => { this.props.alteraValorCheckQuintaFeira(value.target.checked) }} />
                                <b style={{ fontSize: 13, marginLeft: 7, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>Quinta-feira</b>  {this.props.quintaFeiraChat == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({this.props.horarioOperacaoQuintaFeira.start} - {this.props.horarioOperacaoQuintaFeira.end})</span>}
                                {this.props.quintaFeiraChat == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            {this.renderRangerSliderSegunda(this.props.quintaFeiraChat, this.props.horarioOperacaoQuintaFeira, this.props.horarioOperacaoQuintaFeira2, this.props.modificaHorarioOperacaoQuintaFeira, this.props.modificaHorarioOperacaoQuintaFeira2)}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', margin: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: 700, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5 }}>
                                <input disabled={!this.props.switchUtilizarChat} type="checkbox" checked={this.props.sextaFeiraChat} onChange={(value) => { this.props.alteraValorCheckSextaFeira(value.target.checked) }} />
                                <b style={{ fontSize: 13, marginLeft: 7, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>Sexta-feira</b>  {this.props.sextaFeiraChat == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({this.props.horarioOperacaoSextaFeira.start} - {this.props.horarioOperacaoSextaFeira.end})</span>}
                                {this.props.sextaFeiraChat == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            {this.renderRangerSliderSegunda(this.props.sextaFeiraChat, this.props.horarioOperacaoSextaFeira, this.props.horarioOperacaoSextaFeira2, this.props.modificaHorarioOperacaoSextaFeira, this.props.modificaHorarioOperacaoSextaFeira2)}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', margin: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: 700, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5 }}>
                                <input disabled={!this.props.switchUtilizarChat} type="checkbox" checked={this.props.sabadoChat} onChange={(value) => { this.props.alteraValorCheckSabadoFeira(value.target.checked) }} />
                                <b style={{ fontSize: 13, marginLeft: 7, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>Sábado</b>  {this.props.sabadoChat == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({this.props.horarioOperacaoSabadoFeira.start} - {this.props.horarioOperacaoSabadoFeira.end})</span>}
                                {this.props.sabadoChat == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            {this.renderRangerSliderSegunda(this.props.sabadoChat, this.props.horarioOperacaoSabadoFeira, this.props.horarioOperacaoSabadoFeira2, this.props.modificaHorarioOperacaoSabadoFeira, this.props.modificaHorarioOperacaoSabadoFeira2)}


                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', margin: 10, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: 700, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 5 }}>
                                <input disabled={!this.props.switchUtilizarChat} type="checkbox" checked={this.props.domingoChat} onChange={(value) => { this.props.alteraValorCheckDomingoFeira(value.target.checked) }} />
                                <b style={{ fontSize: 13, marginLeft: 7, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>Domingo</b>  {this.props.domingoChat == true && <span style={{ fontSize: 14, marginLeft: 10, color: this.props.switchUtilizarChat == false ? '#9fadb7' : this.props.coresLetraPadrao }}>({this.props.horarioOperacaoDomingoFeira.start} - {this.props.horarioOperacaoDomingoFeira.end})</span>}
                                {this.props.domingoChat == false && <b style={{ fontSize: 13, marginLeft: 10, color: '#9fadb7' }}>(Fechado o dia inteiro)</b>}
                            </div>
                            {this.renderRangerSliderSegunda(this.props.domingoChat, this.props.horarioOperacaoDomingoFeira, this.props.horarioOperacaoDomingoFeira2, this.props.modificaHorarioOperacaoDomingoFeira, this.props.modificaHorarioOperacaoDomingoFeira2)}


                        </div>

                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoConfigChatOnline == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                } else {
                                    this.props.alteraConfiguracaoChatOnline(this.props.segundaFeiraChat, this.props.tercaFeiraChat, this.props.quartaFeiraChat, this.props.quintaFeiraChat, this.props.sextaFeiraChat, this.props.sabadoChat, this.props.domingoChat,
                                        this.props.horarioOperacaoSegundaFeira, this.props.horarioOperacaoSegundaFeira2, this.props.horarioOperacaoTercaFeira, this.props.horarioOperacaoTercaFeira2,
                                        this.props.horarioOperacaoQuartaFeira, this.props.horarioOperacaoQuartaFeira2, this.props.horarioOperacaoQuintaFeira, this.props.horarioOperacaoQuintaFeira2,
                                        this.props.horarioOperacaoSextaFeira, this.props.horarioOperacaoSextaFeira2, this.props.horarioOperacaoSabadoFeira, this.props.horarioOperacaoSabadoFeira2,
                                        this.props.horarioOperacaoDomingoFeira, this.props.horarioOperacaoDomingoFeira2, this.props.switchUtilizarChat, this.props.switchUtilizar2HorariosChat, this.props.fusoHorarioSelelcionado)
                                }
                            }}>
                                Salvar
                            </ButtonEntrar>}
                            {this.props.loadingBotaoConfigChatOnline == true && <ClipLoader
                                css={{ margin: 10 }}
                                size={40}
                                color={this.props.corBotaoPadrao}
                                loading
                            />}
                        </ContainerBotaoAlterar>
                    </ContainerCfg>

                    <ContainerCfg style={{}} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Configuração da Empresa</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>

                            <DivInputComentarChamado >
                                <TextMensagem corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Nome da Empresa:</TextMensagem>
                                <InputDescricaoEmpresa
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaNomeEmpresa(value.target.value) }}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    value={this.props.nomeEmpresa}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    placeholder="  Nome da Empresa"
                                    corBotaoPadrao={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                            <TextDescCor style={{ marginTop: 15 }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cores do Sistema:</TextDescCor>
                            <ContainerCores corLetraSecundaria={this.props.coresLetraSecundario}>

                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Principal:</TextDescCor>

                                    <DivColor onClick={this.handleClick}>

                                        <DivCor corSelecionadoDinamico={this.props.backgroundPrimeiro} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose} />
                                    <SketchPicker color={this.props.backgroundPrimeiro} onChange={(color) => { this.props.modificaCorFundoPrincipal(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Secundaria:</TextDescCor>

                                    <DivColor onClick={this.handleClick2}>

                                        <DivCor corSelecionadoDinamico={this.props.backgroundSegundo} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker2 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose2} />
                                    <SketchPicker color={this.props.backgroundSegundo} onChange={(color) => { this.props.modificaCorFundoSecundario(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor dos Itens Selecionaveis:</TextDescCor>

                                    <DivColor onClick={this.handleClick3}>

                                        <DivCor corSelecionadoDinamico={this.props.backgroundMaisEscuro} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker3 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose3} />
                                    <SketchPicker color={this.props.backgroundMaisEscuro} onChange={(color) => { this.props.modificaCorFundoItensSelecionaveis(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Botão Principal:</TextDescCor>

                                    <DivColor onClick={this.handleClick4}>

                                        <DivCor corSelecionadoDinamico={this.props.corBotaoPadrao} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker4 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose4} />
                                    <SketchPicker color={this.props.corBotaoPadrao} onChange={(color) => { this.props.modificaCorFundoBotaoPrincipal(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Botão Secundario:</TextDescCor>

                                    <DivColor onClick={this.handleClick5}>

                                        <DivCor corSelecionadoDinamico={this.props.corSegundoBotaoPadrao} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker5 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose5} />
                                    <SketchPicker color={this.props.corSegundoBotaoPadrao} onChange={(color) => { this.props.modificaCorFundoBotaoSecundario(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Letra Dentro do Botão:</TextDescCor>

                                    <DivColor onClick={this.handleClick6}>

                                        <DivCor corSelecionadoDinamico={this.props.letraBotaoPadrao} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker6 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose6} />
                                    <SketchPicker color={this.props.letraBotaoPadrao} onChange={(color) => { this.props.modificaCorLetraBotao(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Letra Principal/ Borda Principal:</TextDescCor>

                                    <DivColor onClick={this.handleClick7}>

                                        <DivCor corSelecionadoDinamico={this.props.coresLetraPadrao} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker7 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose7} />
                                    <SketchPicker color={this.props.coresLetraPadrao} onChange={(color) => { this.props.modificaCorLetraPrincipal(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Letra Secundaria/ Borda Secundaria:</TextDescCor>

                                    <DivColor onClick={this.handleClick8}>

                                        <DivCor corSelecionadoDinamico={this.props.coresLetraSecundario} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker8 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose8} />
                                    <SketchPicker color={this.props.coresLetraSecundario} onChange={(color) => { this.props.modificaCorLetraSecundaria(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Letra Descrição dos Campos:</TextDescCor>

                                    <DivColor onClick={this.handleClick9}>

                                        <DivCor corSelecionadoDinamico={this.props.corLetraDescricaoDosCampos} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker9 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose9} />
                                    <SketchPicker color={this.props.corLetraDescricaoDosCampos} onChange={(color) => { this.props.modificaCorDescricaoDosCampos(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Fundo Menu:</TextDescCor>

                                    <DivColor onClick={this.handleClick10}>

                                        <DivCor corSelecionadoDinamico={this.props.corFundoMenu} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker10 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose10} />
                                    <SketchPicker color={this.props.corLetraDescricaoDosCampos} onChange={(color) => { this.props.modificaCorFundoMenu(color.hex) }} />
                                </DivColorPiciker> : null}
                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Letra Menu:</TextDescCor>

                                    <DivColor onClick={this.handleClick11}>

                                        <DivCor corSelecionadoDinamico={this.props.corLetraMenu} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker11 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose11} />
                                    <SketchPicker color={this.props.corLetraDescricaoDosCampos} onChange={(color) => { this.props.modificaCorLetraMenu(color.hex) }} />
                                </DivColorPiciker> : null}

                                <DivLetraeCor>
                                    <TextDescCor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cor Principal do Chat:</TextDescCor>

                                    <DivColor onClick={this.handleClick12}>

                                        <DivCor corSelecionadoDinamico={this.props.corPrincipalChat} />
                                    </DivColor>
                                </DivLetraeCor>
                                {this.state.displayColorPicker12 ? <DivColorPiciker >
                                    <DivCSelectColor onClick={this.handleClose12} />
                                    <SketchPicker color={this.props.corPrincipalChat} onChange={(color) => { this.props.modificaCorPrincipalChat(color.hex) }} />
                                </DivColorPiciker> : null}

                                {this.props.loadingBotaoAlterarNomeConfigEmpresa == false && <ButtonResetCores data-tip="Retornar com as cores padrão do DropDesk" data-for="tip-top" backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                    this._toggleModalResetarcores();
                                }}>
                                    Resetar Cores
                                </ButtonResetCores>}
                                {this.props.loadingBotaoAlterarNomeConfigEmpresa == true && <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={this.props.corBotaoPadrao}
                                    loading
                                />}

                            </ContainerCores>
                            <TextDescCor style={{ marginTop: 15 }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Logo Marca:</TextDescCor>
                            <ContainerCores corLetraSecundaria={this.props.coresLetraSecundario}>
                                <div data-tip="Esta logo irá aparecer na tela de login da area do cliente" data-for="tip-top" style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: 266, width: 266, borderRadius: 10, margin: 10, backgroundColor: this.props.coresLetraSecundario }}>
                                    {this.renderImagemLogoTipo()}
                                </div>
                                <Dropzone
                                    accept="image/*"
                                    multiple={false}
                                    noKeyboard
                                    maxSize={5e+6}
                                    noDrag
                                    onDrop={acceptedFiles => this.escolheImagemLogoTipo(acceptedFiles)}>
                                    {({ getRootProps, getInputProps, isDragActive }) => {

                                        return (
                                            /*     <section> */
                                            <div  {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <ButtonEscolherLogoTipo data-tip="Escolha seu logotipo" data-for="tip-top" backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button">
                                                    Escolher imagem...
                                                </ButtonEscolherLogoTipo>
                                            </div>
                                            /*   </section> */
                                        )
                                    }}
                                </Dropzone>
                            </ContainerCores>
                        </DivConfiguracoes>
                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarNomeConfigEmpresa == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                } else {
                                    this.props.alteraNomeEmpresa(this.props.nomeEmpresa, this.props.backgroundPrimeiro, this.props.backgroundSegundo, this.props.backgroundMaisEscuro, this.props.coresLetraPadrao, this.props.coresLetraSecundario,
                                        this.props.corBotaoPadrao, this.props.letraBotaoPadrao, this.props.corSegundoBotaoPadrao, this.props.corLetraDescricaoDosCampos, this.props.corFundoMenu, this.props.corLetraMenu, this.props.logotipo, this.props.arquivoLogoTipoCompleto, this.props.corPrincipalChat)
                                }
                            }}>
                                Salvar
                            </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarNomeConfigEmpresa == true && <ClipLoader
                                css={{ margin: 10 }}
                                size={40}
                                color={this.props.corBotaoPadrao}
                                loading
                            />}
                        </ContainerBotaoAlterar>
                    </ContainerCfg>


                    <ContainerCfg style={{}} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Notificações</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Novo atendimento:</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox6" type="checkbox" checked={this.props.notifAtendAberto} onChange={(value) => { this.props.alteraSwitchNotifAtendABertoPorMim(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox6" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Atendimentos atribuídos a mim</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox7" type="checkbox" checked={this.props.notifAtendEstouAtendendo} onChange={(value) => { this.props.alteraSwitchNotifAtendEstouAtendendo(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox7" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Respostas nos atendimentos atribuídos a mim</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox8" type="checkbox" checked={this.props.notifRespAtendAbertoPorMim} onChange={(value) => { this.props.alteraSwitchNotifRespAtendAbertoPorMim(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox8" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Respostas nos atendimentos que estou atendendo</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox9" type="checkbox" checked={this.props.notifRespAtendQueEstouAtendendo} onChange={(value) => { this.props.alteraSwitchNotifRespAtendQueEstouAtendendo(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox9" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            {this.props.isAdmin === true && <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Comentários Interno nos Atendimentos</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox10" type="checkbox" checked={this.props.notifComentarioInterno} onChange={(value) => { this.props.alteraSwitchNotifComentarioInterno(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox10" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>}
                        </DivConfiguracoes>
                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarNotificacao == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.props.alterarConfiguracaoNotificao(this.props.notifAtendAberto, this.props.notifAtendEstouAtendendo, this.props.notifRespAtendAbertoPorMim, this.props.notifRespAtendQueEstouAtendendo, this.props.notifComentarioInterno)
                            }}>
                                Salvar
                            </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarNotificacao == true &&
                                <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={this.props.corBotaoPadrao}
                                    loading
                                />
                            }

                        </ContainerBotaoAlterar>

                    </ContainerCfg>
                    <ContainerCfg style={{ paddingBottom: 30 }} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>DropDesk Cliente ( Portal do Cliente APP e WEB )</TextCabecalho>
                        </DivCabecalhoCfg>

                        <ContainerPermitir style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 10 }}>
                            <TextPermitir data-tip="Ao marcar está opção, obrigatóriamente será necessário o cadastro de Problemas Pré Definidos<br/>(Exemplo: mouse com defeito, problema no teclado...) e o cliente através do app DropDesk - Cliente<br/> irá apenas selecionar os Problemas Pré Definidos<br/> ao invés de digitar o motivo do Problema ao abrir um novo chamado." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Utilizar Problema Pré Definido<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir>
                            <CheckBoxWrapper>
                                <CheckBox id="checkbox4" type="checkbox" checked={this.props.verificaCriarChamadoProblemaEstatico} onChange={(value) => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    } else {
                                        this.props.alteraValorSwitchConfigChamadoEstatico(value.target.checked)
                                    }
                                }} />
                                <CheckBoxLabel htmlFor="checkbox4" />
                            </CheckBoxWrapper>

                        </ContainerPermitir>
                        {this.props.verificaCriarChamadoProblemaEstatico == true && <ContainerPermitirProblemaPReDefinido corBordaDinamica={this.props.coresLetraSecundario}>
                            <ContainerSelectSearch >

                                <p style={{ marginLeft: 5, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>CADASTRE OS PROBLEMAS PRÉ DEFINIDOS:</p>
                                <SelectSearch
                                    renderValue={(valueProps) =>
                                        <DivInputSearch>
                                            <InputSearch
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                                placeholderColor={this.props.corLetraSecundaria}
                                                corBordaDinamica={this.props.corBotaoPadrao}
                                                {...valueProps} ></InputSearch>
                                            <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                        </DivInputSearch>
                                    }
                                    search={this.props.problemasEstaticos.length == 0 ? false : true}
                                    closeOnSelect={false}
                                    printOptions="on-focus"
                                    multiple
                                    value={this.props.problemasSelecionado}
                                    onChange={(value) => {
                                        this.props.modificaProblemaSelecionadoSectioned(value);
                                    }}
                                    options={this.props.problemasEstaticos}
                                    placeholder={this.props.problemasEstaticos.length == 0 ? `Nenhum Problema cadastrado, Por favor cadastre!` : "Pesquise Pelo Problema"}
                                />

                            </ContainerSelectSearch>
                            <DivBotaoSalvarComentario>
                                {this.props.loadingBotaoNovoPRoblema == false && <ButtonSalvarComentario onClick={() => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    } else {
                                        this._toggleModalVisibleDescricaoProblema()
                                    }
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Novo Problema</TextBotaoSalvarComentar>
                                </ButtonSalvarComentario>}
                                {this.props.loadingBotaoNovoPRoblema == true &&
                                    <ClipLoader
                                        css={{ margin: 10 }}
                                        size={40}
                                        color={this.props.corBotaoPadrao}
                                        loading
                                    />
                                }
                            </DivBotaoSalvarComentario>
                        </ContainerPermitirProblemaPReDefinido>}
                        <DivConfiguracoes>

                            <DivInputComentarChamado >
                                <TextMensagem corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Mensagem de avaliação após finalizar chamado:</TextMensagem>
                                <InputDescricaoChamado2
                                    type="textarea"
                                    onChange={(value) => { this.props.alteraMensagemAvaliacao(value.target.value) }}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    value={this.props.mensagemAvaliacao}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    placeholder="  Informe a mensagem..."
                                    corBotaoPadrao={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                        </DivConfiguracoes>
                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarConfigAvaliacao == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.props.alteraConfiguracaoAvaliacao(this.props.mensagemAvaliacao, this.props.verificaCriarChamadoProblemaEstatico, this.props.problemasSelecionado, this.props.problemasEstaticos)
                            }}>
                                Salvar
                            </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarConfigAvaliacao == true &&
                                <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={this.props.corBotaoPadrao}
                                    loading
                                />
                            }
                        </ContainerBotaoAlterar>
                        <div style={{ display: 'flex', height: 20, justifyContent: 'flex-end', alignItems: 'center' }}>
                            <p style={{ color: this.props.coresLetraPadrao, fontStyle: 'italic', marginRight: 4 }}>Versão 2.28.12</p>
                        </div>
                    </ContainerCfg>
                </Container>
            )
        }
    }
    render() {


        return (
            <>
                {this.renderLoadingConfiguracao()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            corFundoMenu: state.CoresPadraoSistema.corFundoMenu,
            corLetraMenu: state.CoresPadraoSistema.corLetraMenu,
            corPrincipalChat: state.CoresPadraoSistema.corPrincipalChat,
            logotipo: state.CoresPadraoSistema.logotipo,
            arquivoLogoTipoCompleto: state.CoresPadraoSistema.arquivoLogoTipoCompleto,

            nomeRota: state.HeaderReducer.nomeRota,
            modalNovoAtendimento: state.ChamadoReducer.modalNovoAtendimento,
            visibleSelectSearch: state.ChamadoReducer.visibleSelectSearch,

            verificaConexaoInternetConfigAvaliacao: state.ConfiguracaoReducer.verificaConexaoInternetConfigAvaliacao,
            premium: state.PremiumReducer.premium,
            loadingConfiAvaliacao: state.ConfiguracaoReducer.loadingConfiAvaliacao,
            loadingBotaoAlterarConfigAvaliacao: state.ConfiguracaoReducer.loadingBotaoAlterarConfigAvaliacao,
            switchMEnsagemAvaliacao: state.ConfiguracaoReducer.switchMEnsagemAvaliacao,
            mensagemAvaliacao: state.ConfiguracaoReducer.mensagemAvaliacao,
            loadingConfigEmpresa: state.ConfiguracaoReducer.loadingConfigEmpresa,
            nomeEmpresa: state.ConfiguracaoReducer.nomeEmpresa,
            loadingBotaoAlterarNomeConfigEmpresa: state.ConfiguracaoReducer.loadingBotaoAlterarNomeConfigEmpresa,
            licensawebouapp: state.PremiumReducer.licensawebouapp,

            loadingConfigAtendimentos: state.ConfiguracaoReducer.loadingConfigAtendimentos,
            switchObrigatorioAtribuirAtendenteAtendimento: state.ConfiguracaoReducer.switchObrigatorioAtribuirAtendenteAtendimento,
            loadingBotaoAlterarConfigAtendimento: state.ConfiguracaoReducer.loadingBotaoAlterarConfigAtendimento,
            verificaConexaoInternetConfigAtendimento: state.ConfiguracaoReducer.verificaConexaoInternetConfigAtendimento,
            atendenteVeApenasProprioChamado: state.ConfiguracaoReducer.atendenteVeApenasProprioChamado,
            agruparAtendenteSetor: state.ConfiguracaoReducer.agruparAtendenteSetor,
            verificaCriarChamadoProblemaEstatico: state.ConfiguracaoReducer.verificaCriarChamadoProblemaEstatico,
            problemasEstaticos: state.ConfiguracaoReducer.problemasEstaticos,
            problemasSelecionado: state.ConfiguracaoReducer.problemasSelecionado,

            respostasPadroes: state.ConfiguracaoReducer.respostasPadroes,
            respostasSelecionadas: state.ConfiguracaoReducer.respostasSelecionadas,
            loadingBotaoNovoPRoblema: state.ConfiguracaoReducer.loadingBotaoNovoPRoblema,
            descricaoProblemaEstatico: state.ConfiguracaoReducer.descricaoProblemaEstatico,
            loadingCarregandoProblemas: state.ConfiguracaoReducer.loadingCarregandoProblemas,
            notifAtendAberto: state.NotificacaoReducer.notifAtendAberto,
            notifAtendEstouAtendendo: state.NotificacaoReducer.notifAtendEstouAtendendo,
            notifRespAtendAbertoPorMim: state.NotificacaoReducer.notifRespAtendAbertoPorMim,
            notifRespAtendQueEstouAtendendo: state.NotificacaoReducer.notifRespAtendQueEstouAtendendo,
            notifComentarioInterno: state.NotificacaoReducer.notifComentarioInterno,
            loadingBotaoAlterarNotificacao: state.NotificacaoReducer.loadingBotaoAlterarNotificacao,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,

            switchUtilizarChat: state.ConfiguracaoReducer.switchUtilizarChat,
            switchUtilizar2HorariosChat: state.ConfiguracaoReducer.switchUtilizar2HorariosChat,
            horarioOperacaoSegundaFeira: state.ConfiguracaoReducer.horarioOperacaoSegundaFeira,
            horarioOperacaoSegundaFeira2: state.ConfiguracaoReducer.horarioOperacaoSegundaFeira2,

            horarioOperacaoTercaFeira: state.ConfiguracaoReducer.horarioOperacaoTercaFeira,
            horarioOperacaoQuartaFeira: state.ConfiguracaoReducer.horarioOperacaoQuartaFeira,
            horarioOperacaoQuintaFeira: state.ConfiguracaoReducer.horarioOperacaoQuintaFeira,
            horarioOperacaoSextaFeira: state.ConfiguracaoReducer.horarioOperacaoSextaFeira,
            horarioOperacaoSabadoFeira: state.ConfiguracaoReducer.horarioOperacaoSabadoFeira,
            horarioOperacaoDomingoFeira: state.ConfiguracaoReducer.horarioOperacaoDomingoFeira,

            horarioOperacaoTercaFeira2: state.ConfiguracaoReducer.horarioOperacaoTercaFeira2,
            horarioOperacaoQuartaFeira2: state.ConfiguracaoReducer.horarioOperacaoQuartaFeira2,
            horarioOperacaoQuintaFeira2: state.ConfiguracaoReducer.horarioOperacaoQuintaFeira2,
            horarioOperacaoSextaFeira2: state.ConfiguracaoReducer.horarioOperacaoSextaFeira2,
            horarioOperacaoSabadoFeira2: state.ConfiguracaoReducer.horarioOperacaoSabadoFeira2,
            horarioOperacaoDomingoFeira2: state.ConfiguracaoReducer.horarioOperacaoDomingoFeira2,


            segundaFeiraChat: state.ConfiguracaoReducer.segundaFeiraChat,
            tercaFeiraChat: state.ConfiguracaoReducer.tercaFeiraChat,
            quartaFeiraChat: state.ConfiguracaoReducer.quartaFeiraChat,
            quintaFeiraChat: state.ConfiguracaoReducer.quintaFeiraChat,
            sextaFeiraChat: state.ConfiguracaoReducer.sextaFeiraChat,
            sabadoChat: state.ConfiguracaoReducer.sabadoChat,
            domingoChat: state.ConfiguracaoReducer.domingoChat,
            loadingBotaoConfigChatOnline: state.ConfiguracaoReducer.loadingBotaoConfigChatOnline,

            fusoHorarioSelelcionado: state.ConfiguracaoReducer.fusoHorarioSelelcionado,
            fusoHorarios: state.ConfiguracaoReducer.fusoHorarios,

            mensagemRespostaPadrao: state.ConfiguracaoReducer.mensagemRespostaPadrao,
            descricaoRespostaPadrao: state.ConfiguracaoReducer.descricaoRespostaPadrao,
            loadingBotaoNovaResposta: state.ConfiguracaoReducer.loadingBotaoNovaResposta,
            clicouCFGchat: state.ChamadoReducer.clicouCFGchat,
            isAdmin: state.PerfilReducer.isAdmin,
            keyUsuarioLogado: state.PerfilReducer.keyUsuarioLogado,
            switchVisualizaChamadosDosSetoresVinculadosADM: state.ConfiguracaoReducer.switchVisualizaChamadosDosSetoresVinculadosADM,

            sessionCliente: state.ConfiguracaoReducer.sessionCliente,
            statusConnect: state.ConfiguracaoReducer.statusConnect,
            qrCode: state.ConfiguracaoReducer.qrCode,
            configWhatsapp: state.ChamadoReducer.configWhatsapp,

            dataValidoAte: state.PremiumReducer.dataValidoAte,
            dataCompra: state.PremiumReducer.dataCompra,
            dataValidadeAtual: state.PremiumReducer.dataValidadeAtual,

            mensagemBemVindo: state.ConfiguracaoReducer.mensagemBemVindo,
            mensagemEscolhaDepartamento: state.ConfiguracaoReducer.mensagemEscolhaDepartamento,
            mensagemFilaAtendimento: state.ConfiguracaoReducer.mensagemFilaAtendimento,
            mensagemFinalizacaoAtendimento: state.ConfiguracaoReducer.mensagemFinalizacaoAtendimento,
            mensagemForaHorarioOperacao: state.ConfiguracaoReducer.mensagemForaHorarioOperacao,
            loadingBotaoAlterarConfigWhatsApp: state.ConfiguracaoReducer.loadingBotaoAlterarConfigWhatsApp,

            limitesEmEEspera: state.ConfiguracaoReducer.limitesEmEEspera,
            limiteEmEsperaSelecionado: state.ConfiguracaoReducer.limiteEmEsperaSelecionado,

            enviaAtendimentoParaSetorAposLimite: state.ConfiguracaoReducer.enviaAtendimentoParaSetorAposLimite,
            keySetorSelecionadoParaEnviarAtendimentoAposLimite: state.ConfiguracaoReducer.keySetorSelecionadoParaEnviarAtendimentoAposLimite,
            setoresParaLimite: state.ConfiguracaoReducer.setoresParaLimite,
            obrigatorioInformarResolucaoChamadoConfig: state.ConfiguracaoReducer.obrigatorioInformarResolucaoChamadoConfig,


            mostrarNomeAtendenteMensagem: state.ConfiguracaoReducer.mostrarNomeAtendenteMensagem,
            desativarAssistenteVirtualWpp: state.ConfiguracaoReducer.desativarAssistenteVirtualWpp,

            solicitarNomeClientePrimeirocontato: state.ConfiguracaoReducer.solicitarNomeClientePrimeirocontato,

            mensagemRespostaPadraoAlterar: state.ConfiguracaoReducer.mensagemRespostaPadraoAlterar,
            descricaoRespostaPadraoAlterar: state.ConfiguracaoReducer.descricaoRespostaPadraoAlterar,


            obrigatorioInformarMotivoCancelamentoConfig: state.ConfiguracaoReducer.obrigatorioInformarMotivoCancelamentoConfig,

            atendenteVeApenasProprioHistorico: state.ConfiguracaoReducer.atendenteVeApenasProprioHistorico,

            utilizaVersaoBETA: state.ConfiguracaoReducer.utilizaVersaoBETA,

            enviarMensagemTransSetorWppConfig: state.ConfiguracaoReducer.enviarMensagemTransSetorWppConfig,

        }
    );
};
export default connect(mapStateToProps, {
    zeraMensagemSemInternetConfigAvaliacao, modificaNomeEmpresa,
    alteraMensagemAvaliacao, alteraValorSwitchConfigAvaliacao,
    zeraMensagemSemInternetConfigEmpresa,
    alteraSwitchNotifAtendABertoPorMim, alteraSwitchNotifAtendEstouAtendendo, alteraSwitchNotifRespAtendAbertoPorMim,
    alteraSwitchNotifRespAtendQueEstouAtendendo,
    verificaConexaoInternetNotificacao, zeraverificaSeAlterouComSucessoNotificacao,

    modificaCorFundoPrincipal, modificaCorFundoSecundario, modificaCorFundoItensSelecionaveis, modificaCorFundoBotaoPrincipal,
    modificaCorFundoBotaoSecundario, modificaCorLetraPrincipal, modificaCorLetraSecundaria,
    modificaCorDescricaoDosCampos, modificaCorLetraBotao,

    alteraValorSwitchConfigAtendimento, zeraMensagemSemInternetConfigAtendimento,
    alteraAgrupaAtendenteSetor, alteraAtendenteVeApenasProprioChamado, alteraValorSwitchConfigChamadoEstatico,
    modificaDescricaoProblemaEstatico,
    modificaProblemaSelecionadoSectioned, modificaCorFundoMenu, modificaCorLetraMenu, modificaNomeRotaNoHeader,
    listaConfiguracoes, cadastraProblemaEstatico, alteraConfiguracaoAtendimento,
    alteraConfiguracaoAvaliacao, alterarConfiguracaoNotificao, alteraNomeEmpresa, resetarCores,
    modificaImagemCompletaLogoTipoConfiguracao, modificaLogoTipoTelaConfiguracao, alteraValorSwitchUtilizarChat,
    modificaHorarioOperacaoSegundaFeira, alteraValorSwitcUtilizar2HorariosChat, alteraValorCheckSegundaFeira,
    modificaHorarioOperacaoSegundaFeira2,
    modificaHorarioOperacaoTercaFeira2, modificaHorarioOperacaoTercaFeira, modificaHorarioOperacaoQuartaFeira2,
    modificaHorarioOperacaoQuartaFeira, modificaHorarioOperacaoQuintaFeira2, modificaHorarioOperacaoQuintaFeira,
    modificaHorarioOperacaoSextaFeira2, modificaHorarioOperacaoSextaFeira, modificaHorarioOperacaoSabadoFeira, modificaHorarioOperacaoSabadoFeira2,
    modificaHorarioOperacaoDomingoFeira2, modificaHorarioOperacaoDomingoFeira,
    alteraValorCheckTercaFeira, alteraValorCheckQuartaFeira, alteraValorCheckQuintaFeira,
    alteraValorCheckSextaFeira, alteraValorCheckSabadoFeira, alteraValorCheckDomingoFeira, alteraConfiguracaoChatOnline,
    modificaFusoHorarioSelecionado, modificaCorPrincipalChat, cadastraRespostaPadrao, modificaRespostaSelecionada,
    modificaMensagemRespostaPadrao, modificaDescricaoRespostaPadrao, modificaClicouCFGchat,
    desligaOuvintesChamadosFirebase, alteraSwitchNotifComentarioInterno, modificaSwitchAdmVisualizaApenasChamadoSetor,
    modificaMensagemBoasVindasWpp, modificaMensagemEscolhaDepartamento, modificaMensagemFilaAtendimento,
    modificaMensagemFinalizacaoChamado, modificaMensagemForaHorarioOperacao, alteraConfiguracaoWhatsApp,
    modificaLimiteDeEsperaFilaChat, alteraValorSwitchEnviaAtendimentoParaSetorAposLimite,
    modificaKeySetorSelecionadoParaEnviarAtendimentoAposLimiteExport, modificaObrigatorioResolucaoChamado,
    modificaMostrarNomeAtendenteMensagem, modificaDesativarAssistenteVirtualWpp, modificaSolicitarNomeClientePrimeirocontato,
    DivTruncarTexto, excluirRespostaPadrao, modificaDescricaoRespostaPadraoAlterar, modificaMensagemRespostaPadraoAlterar, alterarRespostaPadrao,
    modificaMotivoCancelamentoConfig, modificaAtendenteVeApenasProprioHistorico, alteraValorSwitchUtilizaVersaoBETA,
    modificaEnviarMensagemTransSetorWppConfig
})(withRouter(Configuracao));
