import {
    ENVIAATENDIMENTOSNOTOTALDASHBOARD, ATIVADESATIVALOADINGTELADASHBOARD,
    ENVIACLIENTESNOTOTALDASHBOARD, ENVIATENDIMENTOSHOJEDASHBOARD, ENVIAATENDIMENTOSDESTASEMANADASHBOARD,
    ATIVADESATIVALOADINGREFRESHCONTROL, ENVIATENDIMENTOSPORSTATUSDASHBOARD, ENVIATENDIMENTOSPORPRIORIDADEDASHBOARD,
    ENVIATOTALATENDIMENTOPORPRIORIDADE, ENVIACLIENTEMAISABRIRAMCHAMADOS,
    ENVIASETORESMAISABRIRAMCHAMADOS, ENVIATEMPORESOLUCAOCHAMADOS, ENVIATOALTENDIMENTOSPORLOCALDASHBOARD, ENVIATENDIMENTOSPORLOCALDASHBOARD,
    ENVIATOTALATENDIMENTOSPERIODORESOLUCAO, ENVIASEMCONEXAOINTERNETDASHBOARD, ENVIAPERIODOPERSONALIZADO, MODIFICADATAINICIALCONSULTADASHBOARD,
    MODIFICADATAFINALCONSULTADASHBOARD, MENSAGEMPERIODOPERSONALIZADOERROR,
    ENVIAATENDIMENTOSPORSTATUSRELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPORSTATUSRELATORIODETALHADO, ENVIAATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPORPRIORIDADERELATORIODETALHADO, ENVIATEMPORESOLUCAOCHAMADOSRELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPERIODORESOLUCAORELATORIODETALHADO, ENVIATOTALATENDIMENTOSPORLOCALRELATORIODETALHADO,
    ENVIAATENDIMENTOSPORLOCALRELATORIODETALHADO, ATIVADESATIVALOADINGTELARELATORIOATENDIMENTODETALHADOS,
    ENVIASEMCONEXAOINTERNETTELARELATORIODETALHADO, ENVIAPERIODOPERSONALIZADORELATORIODETALHADI,
    MODIFICASETORRELATORIODETALHADO, MODIFICAATENDENTERELATORIODETALHADO, MODIFICACLIENTETELARELATORIODETALHADO,
    ENVIACLIENTEPARATELARELATORIODETALHADO, LOADINGCLIENTETELARELATORIODETALHADO,
    ENVIAATENDENTERELATORIODETALHADO, ATIVADESATIVALOADINGATENDENTERELATORIODETALHADO,
    ATIVADESATIVALOADINGSETORTELARELATORIODETALHADO, ENVIASETORTELARELATORIODETALHADO, ZERADATAINICIALFILTRORELATORIODETALHADO,
    ZERADATAFINALFILTRORELATORIODETALHADO, MODIFICAFILTROTELARELATORIODETALHADO, MODIFICAFILTROCLIENTESECTIONEDTELARELATORIODETALHADO,
    MODIFICAFILTROATENDENTESECTIONEDTELARELATORIODETALHADO, MODIFICAFILTROSETORSECTIONEDTELARELATORIODETALHADO,
    MODIFICADATAFINALRELATORIODETALHADO, MODIFICADATAINICIALRELATORIODETALHADO, MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR,
    ENVIATEMPOMEDIODESOLUCAOATENDIMENTOS, ENVIASEMCONEXAOINTERNETRELATORIOGERAL, ATIVADESATIVALOADINGRELATORIOGERAL,
    ENVIAPERIODOPERSONALIZADORELATORIOGERAL, ENVIACLIENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
    ENVIAATENDENTEMAISABRIRAMCHAMADOSRELATORIOGERAL, ENVIASETORESMAISABRIRAMCHAMADOSRELATORIOGERAL,
    ENVIATEMPOMEDIODESOLUCAOATENDIMENTOSRELATORIOGERAL, ENVIANOTAMEDIAAVALIACAORELATORIOGERAL,
    ZERADATAFINALFILTRORELATORIOGERAL, ZERADATAINICIALFILTRORELATORIOGERAL, MODIFICADATAINICIALRELATORIOGERAL, MODIFICADATAFINALRELATORIOGERAL,
    ATIVADESATIVALOADINGSEMINTERNETRELATORIOGERAL,
    MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL, ENVIATEXT2MODALINF, ENVIATEXT1MODALINF, ENVIATEXTCABECALHOMODALINF,
    MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO, ENVIATEXTCABECALHOMODALINFRELATORIODETALHADO,
    ENVIATEXT1MODALINFRELATORIODETALHADO, ENVIATEXT2MODALINFRELATORIODETALHADO, ENVIACHAMADOSDETALHADOSPARADOWNLOAD,
    MODIFICAMENSAGEMGERANDORELATORIO, ENVIAPESSOASCOMMAISATENDIMENTOSPARADOWNLOAD,
    MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL, ENVIASPROBLEMASMAISABRIRAMCHAMADOSRELATORIOGERAL,
    MODIFICAFILTROPORDATADASHBOARD, MODIFICAFILTROSELECIONADORELATORIODETALHADO,
    ENVIAATENDIMENTOABERTO, ENVIAATENDIMENTOEMATENDIMENTO, ENVIAATENDMENTOCANCELADO, ENVIAATENDIMENTOFECHADO,
    ENVIATAREFAPARAREDUCER, MODIFICAMODALSAIR, MODIFICALOADINGCONFIGURACOESEMPRESA, ENVIANOTIFICACOESPARAREDUCER,
    ENVIATOTALNOTIFICACOESPARAREDUCER, ENVIATEMPOMEDIORESPOSTAATENDIMENTOS,
    ENVIACLIENTESATIVOSGRAFICO, ENVIACLIENTESVIAANUNCIO, ENVIATIPOPLANOGRAFICO, ENVIAFORMAPAGAMENTOGRAFICO, ENVIAASSINADOVIAWEBAPPGRAFICO,
    MODIFICADATAINICIALRELATORIOCRM, MODIFICADATAFINALRELATORIOCRM, ENVIAPLANOSMAISASSINADOS,
    ENVIAESTADOSCOMAMAISCLIENTES, ENVIASEGMENTOSCOMMAISCLIENTES, ATIVADESATIVALOADINGRELATORIOCRM, ENVIAPLANOSMAISASSINANTESPRABAIXAR,
    ENVIAARRAYDEDADOSDAEMPRESAPAINELADM,
    MODIFICAMEMORIACONTAINERWPP, MODIFICASTATUSCONTAINERWPP, MODIFICACONFIGURACOESINTERNASWPP, MODIFICASTATUSCONEXAOWPPPAINELADM,
    MODIFICANIVELBATERIA, MODIFICAMODELOCELULAR, MODIFICAVERSAOOS,
    MODIFICACONTAINERLISTAEMPRESA, MODIFICAURLAPILISTAEMPRESA, MODIFICANUMEROCONECTADOWPP, MODIFICACLIENTESEXPORTARCLIENTES,
    ENVIANOVASATIVACOESNOPERIODO, ENVIASEGMENTOSCOMMAISCANCELAMENTOS, MODIFICADATACONEXAO,
    MODIFICALOADINGBOTAOATUALIZASTATUSCONEXAOWPP, MODIFICAINFOUSUARIOPAINELADM, MODIFICAUTILIZAVERSAOBETAPAINELADM,
    MODIFICANEWEMAIL,MODIFICAOLDEMAIL
} from '../actions/types';


const INITIAL_STATE = {
    atendimentosHoje: 0,
    atendimentosEstaSemana: 0,
    clientesNoTotal: 0,
    atendimentosNoTotal: 0,
    atendimentoAberto: 0,
    atendimentoEmAtendimento: 0,
    atendimentoFechado: 0,
    atendimentoCancelado: 0,
    atendimentosPorStatus: {},
    atendimentosPorPrioridade: [],
    atendimentosPorTempoResolucao: [],
    atendimentosPorLocal: [],
    clientesMaisAbriramChamados: [],
    setoresMaisAbriramChamados: [],
    totalAtendimentoPorPrioridade: 0,
    totalAtendimentoPorLocal: 0,
    totalPeriodoResolucaoAtendimento: 0,
    loadingDashBoard: true,
    loadingRefreshControl: false,
    semInternetDashBoard: false,
    periodoPersonalizadoRelatorio: '',
    dataInicialConsulta: null,
    dataFinalConsulta: null,
    mensagemErrorConsultaDashboardPersonalizado: '',

    loadingRelatorioDetalhado: true,
    loadingSemInternetRelatorioDetalhado: false,
    loadingRefreshControlRelatorioDetalhado: false,
    periodoPersonalizadoRelatorioDetalhado: '',

    atendimentosAEFC: {},
    totalAtendimentosAEFC: 0,
    atendimentosPorPrioridadeRelatorioDetalhado: {},
    totalAtendimentosPorPrioridadeRelatorioDetalhado: 0,

    atendimentosPorTempoSolucaoRelatorioDetalhado: {},
    totalAtendimentosPorTempoSolucaoRelatorioDetalhado: 0,
    atendimentosInternoExternoRelatorioDetalhado: {},
    totalAtendimentosInternoExternoRelatorioDetalhado: 0,
    tempoMedioParaSolucaoAtendimento: '0h 00m',
    tempoMedioPrimeiraResposta: '0h 00m',
    filtroRelatorioDetalhado: '',

    clientesRelatorioAtendimentoDetalhado: [],
    clienteSelecionadoRelatorioAtendimentoDetalhado: [],
    loadingClientesRelatorioAtendimentoDetalhado: false,

    atendentesRelatorioAtendimentoDetalhado: [],
    atendentesSelecionadoRelatorioAtendimentoDetalhado: [],
    loadingAtendentesRelatorioAtendimentoDetalhado: false,

    SetoresRelatorioAtendimentoDetalhado: [],
    SetoresSelecionadoRelatorioAtendimentoDetalhado: [],
    loadingSetoresRelatorioAtendimentoDetalhado: false,

    dataInicialConsultaFiltroRelatorioDetalhado: null,
    dataFinalConsultaFiltroRelatorioDetalhado: null,
    mensagemErrorFiltraConsultaRelatorioDetalhado: '',

    filtroClienteSectioned: false,
    filtroAtendenteSectioned: false,
    filtroSetorSectioned: false,


    loadingRelatorioAtendimentoGeral: true,
    semInternetRelatorioGeral: '',
    loadingSemInternetRelatorioGeral: false,
    loadingRefreshControlRelatorioGeral: false,
    periodoPesquisaRelatorioGeral: '',
    clientesMaisAbriramChamadosRelatorioGeral: [],
    atendentesMaisAbriramChamadosRelatorioGeral: [],
    setoresMaisAbriramChamadosRelatorioGeral: [],
    problemasMaisAbriramChamadosRelatorioGeral: [],
    tempoMedioSolucaoAtendimentoRelatorioGeral: '',
    mediaSatisfacaoRelatorioGeral: '5,00',

    dataInicialConsultaFiltroRelatorioGeral: null,
    dataFinalConsultaFiltroRelatorioGeral: null,
    modalInformacaoRelatorioGeral: false,
    textCabecalhoModalInf: '',
    text1ModalInf: '',
    text2ModalInf: '',
    modalInformacaoRelatorioDetalhado: false,
    textCabecalhoModalInfoRelatorioDetalhado: '',
    text1ModalInfRelatorioDetalhado: '',
    text2ModalInfRelatorioDetalhado: '',

    arrayChamadosDetalhadosParaDownload: [],
    mensagemGerandoRelatorio: '',

    arrayPessoasComMaisAtendimentosParaDownload: [],
    mensagemGerandoRelatorioVisaoGeral: '',

    filtroPorDataDashboard: '',
    filtroSelecionadoRelatorioDetalhado: '',
    tarefas: [],
    sairDoDropDesk: false,
    loadingConfiguracoesEmpresa: true,
    notificacoes: [],
    totalNotificacoes: 0,

    graficoClienteAtivosCancelados: [],
    graficoClienteViaAnuncio: [],
    graficoNovasAtivacoes: [],
    graficoTipoPlanoMensal: [],
    graficoFormaPagamento: [],
    graficoAssinadoViaWebApp: [],
    dataInicialConsultaRelatorioCRM: null,
    dataFinalConsultaRelatorioCRM: null,
    planosMaisAssinados: [],
    estadosComMaisAssinantes: [],
    segmentosComMaisAssinantes: [],
    segmentosComMaisCancelamentos: [],
    loadingRelatorioCRM: true, //reabilitar para true
    senhaAcessoCRM: 'Team280792DropDesk',
    senhaAcessoADM: 'Suporte41341',
    planosComMaisAssinadosParaBaixar: [],

    arrayDadosEmpresaPainelADM: [],


    memoriaContainerWpp: '',
    statusContainerWpp: '',
    configuracoesInternasWpp: '',
    statusConexaoWppPainelAdm: '',
    nivelBateria: 0,
    modeloCelular: '',
    versaoOS: '',
    api: '',
    container: '',
    numeroConectado: '',

    clientesExportarExcel: [],

    dataUltimaConexao: '',
    loadingBotaoAtualizaStatusConexaoWpp: false,
    dadosUsuarioPesquisadoPainelAdm: [],
    versaoBETA: '',
    oldEmail:'',
    newEmail:'',
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ENVIAATENDIMENTOSNOTOTALDASHBOARD:
            return { ...state, atendimentosNoTotal: action.payload }
        case ATIVADESATIVALOADINGTELADASHBOARD:
            return { ...state, loadingDashBoard: action.payload }
        case ENVIACLIENTESNOTOTALDASHBOARD:
            return { ...state, clientesNoTotal: action.payload }
        case ENVIATENDIMENTOSHOJEDASHBOARD:
            return { ...state, atendimentosHoje: action.payload }
        case ENVIAATENDIMENTOSDESTASEMANADASHBOARD:
            return { ...state, atendimentosEstaSemana: action.payload }
        case ATIVADESATIVALOADINGREFRESHCONTROL:
            return { ...state, loadingRefreshControl: action.payload }
        case ENVIATENDIMENTOSPORSTATUSDASHBOARD:
            return { ...state, atendimentosPorStatus: action.payload }
        case ENVIATENDIMENTOSPORPRIORIDADEDASHBOARD:
            return { ...state, atendimentosPorPrioridade: action.payload }
        case ENVIATOTALATENDIMENTOPORPRIORIDADE:
            return { ...state, totalAtendimentoPorPrioridade: action.payload }
        case ENVIACLIENTEMAISABRIRAMCHAMADOS:
            return { ...state, clientesMaisAbriramChamados: action.payload }
        case ENVIASETORESMAISABRIRAMCHAMADOS:
            return { ...state, setoresMaisAbriramChamados: action.payload }
        case ENVIATEMPORESOLUCAOCHAMADOS:
            return { ...state, atendimentosPorTempoResolucao: action.payload }
        case ENVIATOALTENDIMENTOSPORLOCALDASHBOARD:
            return { ...state, totalAtendimentoPorLocal: action.payload }
        case ENVIATENDIMENTOSPORLOCALDASHBOARD:
            return { ...state, atendimentosPorLocal: action.payload }
        case ENVIATOTALATENDIMENTOSPERIODORESOLUCAO:
            return { ...state, totalPeriodoResolucaoAtendimento: action.payload }
        case ENVIASEMCONEXAOINTERNETDASHBOARD:
            return { ...state, semInternetDashBoard: action.payload }
        case ENVIAPERIODOPERSONALIZADO:
            return { ...state, periodoPersonalizadoRelatorio: action.payload }
        case MODIFICADATAINICIALCONSULTADASHBOARD:
            return { ...state, dataInicialConsulta: action.payload }
        case MODIFICADATAFINALCONSULTADASHBOARD:
            return { ...state, dataFinalConsulta: action.payload }
        case MENSAGEMPERIODOPERSONALIZADOERROR:
            return { ...state, mensagemErrorConsultaDashboardPersonalizado: action.payload }

        case ENVIAATENDIMENTOSPORSTATUSRELATORIODETALHADO:
            return { ...state, atendimentosAEFC: action.payload }
        case ENVIATOTALATENDIMENTOSPORSTATUSRELATORIODETALHADO:
            return { ...state, totalAtendimentosAEFC: action.payload }
        case ENVIAATENDIMENTOSPORPRIORIDADERELATORIODETALHADO:
            return { ...state, atendimentosPorPrioridadeRelatorioDetalhado: action.payload }
        case ENVIATOTALATENDIMENTOSPORPRIORIDADERELATORIODETALHADO:
            return { ...state, totalAtendimentosPorPrioridadeRelatorioDetalhado: action.payload }
        case ENVIATEMPORESOLUCAOCHAMADOSRELATORIODETALHADO:
            return { ...state, atendimentosPorTempoSolucaoRelatorioDetalhado: action.payload }
        case ENVIATOTALATENDIMENTOSPERIODORESOLUCAORELATORIODETALHADO:
            return { ...state, totalAtendimentosPorTempoSolucaoRelatorioDetalhado: action.payload }
        case ENVIATOTALATENDIMENTOSPORLOCALRELATORIODETALHADO:
            return { ...state, totalAtendimentosInternoExternoRelatorioDetalhado: action.payload }
        case ENVIAATENDIMENTOSPORLOCALRELATORIODETALHADO:
            return { ...state, atendimentosInternoExternoRelatorioDetalhado: action.payload }
        case ATIVADESATIVALOADINGTELARELATORIOATENDIMENTODETALHADOS:
            return { ...state, loadingRelatorioDetalhado: action.payload }
        case ENVIASEMCONEXAOINTERNETTELARELATORIODETALHADO:
            return { ...state, loadingSemInternetRelatorioDetalhado: action.payload }
        case ENVIAPERIODOPERSONALIZADORELATORIODETALHADI:
            return { ...state, periodoPersonalizadoRelatorioDetalhado: action.payload }

        case MODIFICASETORRELATORIODETALHADO:
            return { ...state, SetoresSelecionadoRelatorioAtendimentoDetalhado: action.payload }
        case MODIFICAATENDENTERELATORIODETALHADO:
            return { ...state, atendentesSelecionadoRelatorioAtendimentoDetalhado: action.payload }
        case MODIFICACLIENTETELARELATORIODETALHADO:
            return { ...state, clienteSelecionadoRelatorioAtendimentoDetalhado: action.payload }

        case ENVIACLIENTEPARATELARELATORIODETALHADO:
            return { ...state, clientesRelatorioAtendimentoDetalhado: action.payload }
        case LOADINGCLIENTETELARELATORIODETALHADO:
            return { ...state, loadingClientesRelatorioAtendimentoDetalhado: action.payload }

        case ENVIAATENDENTERELATORIODETALHADO:
            return { ...state, atendentesRelatorioAtendimentoDetalhado: action.payload }
        case ATIVADESATIVALOADINGATENDENTERELATORIODETALHADO:
            return { ...state, loadingAtendentesRelatorioAtendimentoDetalhado: action.payload }

        case ATIVADESATIVALOADINGSETORTELARELATORIODETALHADO:
            return { ...state, loadingSetoresRelatorioAtendimentoDetalhado: action.payload }
        case ENVIASETORTELARELATORIODETALHADO:
            return { ...state, SetoresRelatorioAtendimentoDetalhado: action.payload }

        case ZERADATAINICIALFILTRORELATORIODETALHADO:
            return { ...state, dataInicialConsultaFiltroRelatorioDetalhado: action.payload }
        case ZERADATAFINALFILTRORELATORIODETALHADO:
            return { ...state, dataFinalConsultaFiltroRelatorioDetalhado: action.payload }
        case MODIFICAFILTROTELARELATORIODETALHADO:
            return { ...state, filtroRelatorioDetalhado: action.payload }

        case MODIFICAFILTROCLIENTESECTIONEDTELARELATORIODETALHADO:
            return { ...state, filtroClienteSectioned: action.payload }
        case MODIFICAFILTROATENDENTESECTIONEDTELARELATORIODETALHADO:
            return { ...state, filtroAtendenteSectioned: action.payload }
        case MODIFICAFILTROSETORSECTIONEDTELARELATORIODETALHADO:
            return { ...state, filtroSetorSectioned: action.payload }

        case MODIFICADATAFINALRELATORIODETALHADO:
            return { ...state, dataFinalConsultaFiltroRelatorioDetalhado: action.payload }
        case MODIFICADATAINICIALRELATORIODETALHADO:
            return { ...state, dataInicialConsultaFiltroRelatorioDetalhado: action.payload }
        case MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR:
            return { ...state, mensagemErrorFiltraConsultaRelatorioDetalhado: action.payload }
        case ENVIATEMPOMEDIODESOLUCAOATENDIMENTOS:
            return { ...state, tempoMedioParaSolucaoAtendimento: action.payload }

        case ENVIASEMCONEXAOINTERNETRELATORIOGERAL:
            return { ...state, semInternetRelatorioGeral: action.payload }
        case ATIVADESATIVALOADINGRELATORIOGERAL:
            return { ...state, loadingRelatorioAtendimentoGeral: action.payload }
        case ENVIAPERIODOPERSONALIZADORELATORIOGERAL:
            return { ...state, periodoPesquisaRelatorioGeral: action.payload }


        case ENVIACLIENTEMAISABRIRAMCHAMADOSRELATORIOGERAL:
            return { ...state, clientesMaisAbriramChamadosRelatorioGeral: action.payload }
        case ENVIAATENDENTEMAISABRIRAMCHAMADOSRELATORIOGERAL:
            return { ...state, atendentesMaisAbriramChamadosRelatorioGeral: action.payload }
        case ENVIASETORESMAISABRIRAMCHAMADOSRELATORIOGERAL:
            return { ...state, setoresMaisAbriramChamadosRelatorioGeral: action.payload }
        case ENVIATEMPOMEDIODESOLUCAOATENDIMENTOSRELATORIOGERAL:
            return { ...state, tempoMedioSolucaoAtendimentoRelatorioGeral: action.payload }
        case ENVIANOTAMEDIAAVALIACAORELATORIOGERAL:
            return { ...state, mediaSatisfacaoRelatorioGeral: action.payload }
        case ZERADATAFINALFILTRORELATORIOGERAL:
            return { ...state, dataFinalConsultaFiltroRelatorioGeral: action.payload }
        case ZERADATAINICIALFILTRORELATORIOGERAL:
            return { ...state, dataInicialConsultaFiltroRelatorioGeral: action.payload }
        case MODIFICADATAINICIALRELATORIOGERAL:
            return { ...state, dataInicialConsultaFiltroRelatorioGeral: action.payload }
        case MODIFICADATAFINALRELATORIOGERAL:
            return { ...state, dataFinalConsultaFiltroRelatorioGeral: action.payload }
        case ATIVADESATIVALOADINGSEMINTERNETRELATORIOGERAL:
            return { ...state, loadingSemInternetRelatorioGeral: action.payload }
        case MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL:
            return { ...state, modalInformacaoRelatorioGeral: action.payload }

        case ENVIATEXT2MODALINF:
            return { ...state, text2ModalInf: action.payload }
        case ENVIATEXT1MODALINF:
            return { ...state, text1ModalInf: action.payload }
        case ENVIATEXTCABECALHOMODALINF:
            return { ...state, textCabecalhoModalInf: action.payload }
        case MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO:
            return { ...state, modalInformacaoRelatorioDetalhado: action.payload }

        case ENVIATEXTCABECALHOMODALINFRELATORIODETALHADO:
            return { ...state, textCabecalhoModalInfoRelatorioDetalhado: action.payload }
        case ENVIATEXT1MODALINFRELATORIODETALHADO:
            return { ...state, text1ModalInfRelatorioDetalhado: action.payload }
        case ENVIATEXT2MODALINFRELATORIODETALHADO:
            return { ...state, text2ModalInfRelatorioDetalhado: action.payload }

        case ENVIACHAMADOSDETALHADOSPARADOWNLOAD:
            return { ...state, arrayChamadosDetalhadosParaDownload: action.payload }
        case MODIFICAMENSAGEMGERANDORELATORIO:
            return { ...state, mensagemGerandoRelatorio: action.payload }

        case ENVIAPESSOASCOMMAISATENDIMENTOSPARADOWNLOAD:
            return { ...state, arrayPessoasComMaisAtendimentosParaDownload: action.payload }
        case MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL:
            return { ...state, mensagemGerandoRelatorioVisaoGeral: action.payload }
        case ENVIASPROBLEMASMAISABRIRAMCHAMADOSRELATORIOGERAL:
            return { ...state, problemasMaisAbriramChamadosRelatorioGeral: action.payload }

        case MODIFICAFILTROPORDATADASHBOARD:
            return { ...state, filtroPorDataDashboard: action.payload }
        case MODIFICAFILTROSELECIONADORELATORIODETALHADO:
            return { ...state, filtroSelecionadoRelatorioDetalhado: action.payload }
        case ENVIAATENDIMENTOABERTO:
            return { ...state, atendimentoAberto: action.payload }
        case ENVIAATENDIMENTOEMATENDIMENTO:
            return { ...state, atendimentoEmAtendimento: action.payload }
        case ENVIAATENDMENTOCANCELADO:
            return { ...state, atendimentoCancelado: action.payload }
        case ENVIAATENDIMENTOFECHADO:
            return { ...state, atendimentoFechado: action.payload }
        case ENVIATAREFAPARAREDUCER:
            return { ...state, tarefas: action.payload }
        case MODIFICAMODALSAIR:
            return { ...state, sairDoDropDesk: action.payload }
        case MODIFICALOADINGCONFIGURACOESEMPRESA:
            return { ...state, loadingConfiguracoesEmpresa: action.payload }
        case ENVIANOTIFICACOESPARAREDUCER:
            return { ...state, notificacoes: action.payload }
        case ENVIATOTALNOTIFICACOESPARAREDUCER:
            return { ...state, totalNotificacoes: action.payload }
        case ENVIATEMPOMEDIORESPOSTAATENDIMENTOS:
            return { ...state, tempoMedioPrimeiraResposta: action.payload }

        case ENVIACLIENTESATIVOSGRAFICO:
            return { ...state, graficoClienteAtivosCancelados: action.payload }
        case ENVIACLIENTESVIAANUNCIO:
            return { ...state, graficoClienteViaAnuncio: action.payload }
        case ENVIATIPOPLANOGRAFICO:
            return { ...state, graficoTipoPlanoMensal: action.payload }
        case ENVIAFORMAPAGAMENTOGRAFICO:
            return { ...state, graficoFormaPagamento: action.payload }
        case ENVIAASSINADOVIAWEBAPPGRAFICO:
            return { ...state, graficoAssinadoViaWebApp: action.payload }
        case MODIFICADATAINICIALRELATORIOCRM:
            return { ...state, dataInicialConsultaRelatorioCRM: action.payload }
        case MODIFICADATAFINALRELATORIOCRM:
            return { ...state, dataFinalConsultaRelatorioCRM: action.payload }
        case ENVIAPLANOSMAISASSINADOS:
            return { ...state, planosMaisAssinados: action.payload }
        case ENVIAESTADOSCOMAMAISCLIENTES:
            return { ...state, estadosComMaisAssinantes: action.payload }
        case ENVIASEGMENTOSCOMMAISCLIENTES:
            return { ...state, segmentosComMaisAssinantes: action.payload }
        case ENVIASEGMENTOSCOMMAISCANCELAMENTOS:
            return { ...state, segmentosComMaisCancelamentos: action.payload }


        case ATIVADESATIVALOADINGRELATORIOCRM:
            return { ...state, loadingRelatorioCRM: action.payload }

        case ENVIAPLANOSMAISASSINANTESPRABAIXAR:
            return { ...state, planosComMaisAssinadosParaBaixar: action.payload }
        case ENVIAARRAYDEDADOSDAEMPRESAPAINELADM:
            return { ...state, arrayDadosEmpresaPainelADM: action.payload }

        case MODIFICAMEMORIACONTAINERWPP:
            return { ...state, memoriaContainerWpp: action.payload }
        case MODIFICASTATUSCONTAINERWPP:
            return { ...state, statusContainerWpp: action.payload }
        case MODIFICACONFIGURACOESINTERNASWPP:
            return { ...state, configuracoesInternasWpp: action.payload }
        case MODIFICASTATUSCONEXAOWPPPAINELADM:
            return { ...state, statusConexaoWppPainelAdm: action.payload }

        case MODIFICANIVELBATERIA:
            return { ...state, nivelBateria: action.payload }
        case MODIFICAMODELOCELULAR:
            return { ...state, modeloCelular: action.payload }
        case MODIFICAVERSAOOS:
            return { ...state, versaoOS: action.payload }
        case MODIFICACONTAINERLISTAEMPRESA:
            return { ...state, container: action.payload }
        case MODIFICAURLAPILISTAEMPRESA:
            return { ...state, api: action.payload }
        case MODIFICANUMEROCONECTADOWPP:
            return { ...state, numeroConectado: action.payload }

        case MODIFICACLIENTESEXPORTARCLIENTES:
            return { ...state, clientesExportarExcel: action.payload }

        case ENVIANOVASATIVACOESNOPERIODO:
            return { ...state, graficoNovasAtivacoes: action.payload }
        case MODIFICADATACONEXAO:
            return { ...state, dataUltimaConexao: action.payload }
        case MODIFICALOADINGBOTAOATUALIZASTATUSCONEXAOWPP:
            return { ...state, loadingBotaoAtualizaStatusConexaoWpp: action.payload }
        case MODIFICAINFOUSUARIOPAINELADM:
            return { ...state, dadosUsuarioPesquisadoPainelAdm: action.payload }
        case MODIFICAUTILIZAVERSAOBETAPAINELADM:
            return { ...state, versaoBETA: action.payload }

            case MODIFICANEWEMAIL:
            return { ...state, newEmail: action.payload }

            case MODIFICAOLDEMAIL:
            return { ...state, oldEmail: action.payload }
        default:
            return state;
    }
};